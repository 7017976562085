
import { useEffect, useRef, forwardRef, useState, useImperativeHandle } from 'react';
import createApp from '../create-widget';
import { WidgetConfig } from '../config';
import styled from 'styled-components';
import { useCallback } from 'react';
import { UpdateData } from '../app';
import useRadar from '../../../modules/server/hooks/game/panels/use-radar';
import { useMemo } from 'react';



export type MapOptions = {
    showRadar: boolean,
    showEnergy: boolean,
    showZoomControlls: boolean,
    showMoveControlls: boolean,
    adminMode: boolean
}
type Props = {
    onObjectSelected?: (objectId: string) => void,
    centerOn?: string
    options?: Partial<MapOptions>
}

const UIContainer = styled.div`
    position: absolute;
    top: 5px;
    left: 5px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    padding: 5px;
    background: rgba(0,0,0,.8);
    border: 1px solid #3ec4fb;
    border-radius: 5px;

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 10px 1fr 1fr;
        column-gap: 3px;
        row-gap: 3px;
    }

    button {
        background: #030e32;
        border: 1px solid #3ec4fb;
        border-radius: 3px;
        color: #3ec4fb;
    }
`;

const Container = styled.div`
    height: 100%;
    position: relative;

    canvas {
        display: block;
    }
`;

export interface API {
    update(data: UpdateData): void,

    setCenter(id: string | null, smooth?: boolean): void
}

export interface privateAPI extends API {
    zoomIn(): void,
    zoomOut(): void,
    zoomReset(): void,

    moveUp(): void,
    moveDown(): void,
    moveLeft(): void,
    moveRight(): void,
    centerView(): void,
}

const PixiComponent = forwardRef<API, Props>(({ onObjectSelected, centerOn, options }, ref) => {

    const widgetContainer = useRef<HTMLDivElement>(null);
    const [gameAPI, setGameAPI] = useState<privateAPI>();


    const { gameObjects, shipId, tracked, areas } = useRadar();

    useEffect(() => {
        if (gameAPI) {
            gameAPI.update({ gameObjects, shipId, tracked, areas });
        }
    }, [gameAPI, gameObjects, shipId, tracked, areas]);

    useImperativeHandle(ref, () => ({
        update(data: UpdateData) {
            if (gameAPI) {
                gameAPI.update(data);
            }
        },
        setCenter(id: string | null, smooth?: boolean) {
            if (gameAPI) {
                gameAPI.setCenter(id, smooth || false)
            }
        }
    }));

    const { showRadar, showEnergy, showZoomControlls, showMoveControlls, adminMode } = useMemo(() => {
        return {
            showRadar: true,
            showEnergy: false,
            showZoomControlls: true,
            showMoveControlls: true,
            adminMode: false,
            ...options
        }
    }, [options]);

    const showMapControlls = showZoomControlls || showMoveControlls;

    useEffect(() => {

        if (widgetContainer.current) {
            const gameConfig: WidgetConfig = {
                container: widgetContainer.current,
                onObjectSelected,
                centerOn,
                showRadar, showEnergy,
                adminMode
            }

            const { destroy, update, zoomIn, zoomOut, zoomReset, moveUp, moveDown, moveLeft, moveRight, centerView, setCenter } = createApp(gameConfig)

            setGameAPI({ update, zoomIn, zoomOut, zoomReset, moveUp, moveDown, moveLeft, moveRight, centerView, setCenter })

            return () => {
                destroy();
            }
        }

    }, [onObjectSelected, centerOn, showRadar, showEnergy, adminMode]);

    const zoomIn = useCallback(() => {
        gameAPI?.zoomIn();
    }, [gameAPI]);

    const zoomOut = useCallback(() => {
        gameAPI?.zoomOut();
    }, [gameAPI]);

    const zoomReset = useCallback(() => {
        gameAPI?.zoomReset();
    }, [gameAPI]);

    const moveUp = useCallback(() => {
        gameAPI?.moveUp();
    }, [gameAPI]);

    const moveDown = useCallback(() => {
        gameAPI?.moveDown();
    }, [gameAPI]);

    const moveLeft = useCallback(() => {
        gameAPI?.moveLeft();
    }, [gameAPI]);

    const moveRight = useCallback(() => {
        gameAPI?.moveRight();
    }, [gameAPI]);

    const centerView = useCallback(() => {
        gameAPI?.centerView();
    }, [gameAPI]);

    return <Container>
        {showMapControlls && <UIContainer>
            <div className="grid">
                {showZoomControlls && <>
                    <button style={{ gridColumn: 1, gridRow: 1 }} onClick={zoomOut}>-</button>
                    <button style={{ gridColumn: 2, gridRow: 1 }} onClick={zoomReset}>0</button>
                    <button style={{ gridColumn: 3, gridRow: 1 }} onClick={zoomIn}>+</button>
                </>}
                {showMoveControlls && <>
                    <button onClick={moveUp} style={{ gridColumn: 2, gridRow: 3 }}>{'A'}</button>
                    <button onClick={moveLeft} style={{ gridColumn: 1, gridRow: 4 }}>{'<'}</button>
                    <button onClick={centerView} style={{ gridColumn: 2, gridRow: 4 }}>{'x'}</button>
                    <button onClick={moveRight} style={{ gridColumn: 3, gridRow: 4 }}>{'>'}</button>
                    <button onClick={moveDown} style={{ gridColumn: 2, gridRow: 5 }}>{'V'}</button>
                </>}
            </div>
        </UIContainer>}
        <div ref={widgetContainer} style={{ width: '100%', margin: '0 auto', userSelect: 'none', height: '100%' }}></div>
    </Container>
})

export default PixiComponent;
