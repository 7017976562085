import { ArraySchema } from '@colyseus/schema';
import { useEffect, useState } from 'react';


export default function useArraySchema<T>(schema?: ArraySchema<T>) {
    const [value, setValue] = useState<T[]>([]);

    useEffect(() => {
        if (schema) {
            setValue(schema.map(v => v))
            schema.onAdd = (item) => {
                setValue(schema.map(v => v))
            }

            schema.onRemove = (item) => {
                setValue(schema.map(v => v))
            }
        }
    }, [schema, setValue]);

    return value;
}
