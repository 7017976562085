import * as PIXI from 'pixi.js';

const COLOR = 0x3ec4fb;

export default class NavLayer extends PIXI.Container {
    private gfx = new PIXI.Graphics();

    private t0 = new PIXI.Text('0');
    private t90 = new PIXI.Text('90');
    private t180 = new PIXI.Text('180');
    private t270 = new PIXI.Text('270');


    private tc1 = new PIXI.Text('');
    private tc2 = new PIXI.Text('');



    constructor() {
        super();

        this.addChild(this.gfx);

        this.t0.anchor.set(.5);
        this.t0.style.fill = COLOR;
        this.t0.style.fontSize = 12;

        this.t90.anchor.set(.5);
        this.t90.style.fill = COLOR;
        this.t90.style.fontSize = 12;

        this.t180.anchor.set(.5);
        this.t180.style.fill = COLOR;
        this.t180.style.fontSize = 12;

        this.t270.anchor.set(.5);
        this.t270.style.fill = COLOR;
        this.t270.style.fontSize = 12;

        this.tc1.anchor.set(.5);
        this.tc1.style.fill = COLOR;
        this.tc1.style.fontSize = 12;

        this.tc2.anchor.set(.5);
        this.tc2.style.fill = COLOR;
        this.tc2.style.fontSize = 12;


        this.addChild(this.t0);
        this.addChild(this.t90);
        this.addChild(this.t180);
        this.addChild(this.t270);
        this.addChild(this.tc1);
        this.addChild(this.tc2);
    }

    update(size: number, zoom: number) {
        this.gfx.clear();


        const base = getBaseCircle(size * zoom)

        const fstCircle = base;
        const sndCircle = base * 2;

        this.gfx.lineStyle(2, COLOR, .5);
        this.gfx.drawCircle(0, 0, fstCircle / zoom);
        this.gfx.drawCircle(0, 0, sndCircle / zoom);

        this.tc1.text = `${fstCircle}`;
        this.tc2.text = `${sndCircle}`;

        this.tc1.position.set((fstCircle / zoom + 15) * Math.SQRT1_2)
        this.tc2.position.set((sndCircle / zoom + 15) * Math.SQRT1_2)

        const lineCord = (fstCircle/zoom)*2.2;
        const textCord = (fstCircle/zoom)*2.3;



        this.gfx.moveTo(0, -20);
        this.gfx.lineTo(0, -lineCord);

        this.gfx.moveTo(20, 0);
        this.gfx.lineTo(lineCord, 0);

        this.gfx.moveTo(0, 20);
        this.gfx.lineTo(0, lineCord);

        this.gfx.moveTo(-20, 0);
        this.gfx.lineTo(-lineCord, 0);

        this.t0.position.set(0, -textCord);
        this.t90.position.set(textCord, 0);
        this.t180.position.set(0, textCord);
        this.t270.position.set(-textCord, 0);

    }
}


function getBaseCircle(raw: number) {
    const bases = [1,2,2.5,5];

    for (let x = 20; x >= 0; x--) {
        for (let b = bases.length - 1; b >= 0; b--) {
            const dec = Math.pow(10, x);
            const base = bases[b];

            if (raw * .2 > base * dec) {
                return base * dec;
            }
        }
    }

    return 100;
}
