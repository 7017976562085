import styled from "styled-components";
import UserInfo from "../../components/user-info";
import InProgressList from "./in-progress-list";
import OpenGamesList from "./open-games-list";

type Props = {

}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .row {
        margin: 0.5em;
    }

    .header {
        display: flex;
        flex-direction: row;

        h1 {
            flex: 100% 1 1;
            text-align: center;
        }
    }

    .lists {
        display: flex;
        flex-direction: row;

        &>* {
            flex: 45% 1 1;
            padding: 1%;
        }
    }
`;

const ServerLobby: React.FC<Props> = () => {
    return <Container>
        <div className="row header">
            <h1>Server lobby</h1>
            <UserInfo />
        </div>
        <div className="row lists">
            <div>
                <OpenGamesList />
            </div>
            <div>
                <InProgressList />

            </div>
        </div>
    </Container>
}

export default ServerLobby;
