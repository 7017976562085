import { useCallback, useMemo } from "react";
import { useGame, useMyShip } from "../../..";
import { MainEngineSchema } from "../../../../../schemas/MainEngineSchema";
import { ShipStatusSchema } from "../../../../../schemas/ShipStatusSchema";
import useBasicSchema from "../../use-basic-schema";

export default function useMainEngine() {
    const { ship } = useMyShip();
    const { room } = useGame();

    const angle = useBasicSchema<MainEngineSchema, number>(ship?.mainEngine, 'angle');
    const velosity = useBasicSchema<MainEngineSchema, number>(ship?.mainEngine, 'velosity');
    const maxVelosity = useBasicSchema<MainEngineSchema, number>(ship?.mainEngine, 'maxVelosity');
    const powerLevel = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'mainEngine');

    const course = useMemo(() => {
        return (angle || 0) * (180 / Math.PI);
    }, [angle])

    const isActive = true;

    const setParams = useCallback((data : {velosity: number, course: number}) => {
        if (room) {
            console.log('set-main-engine-params');
            room.send('set-main-engine-params', {
                velosity: data.velosity,
                angle: data.course * (Math.PI / 180)
            });

        }
    }, [room])

    return {
        isActive,
        maxVelosity,
        setParams,
        powerLevel,

        velosity,
        course
    }
}
