
import { useEffect, useRef, forwardRef, useState, useImperativeHandle } from 'react';
import createApp from '../create-widget';
import { WidgetConfig } from '../config';
import { GameObjectSchema } from '../../../../../schemas/GameObjectSchema';
import useMic from '../../../../../modules/server/hooks/game/panels/use-mic';

type Props = {}

export type UpdateData = {
    gameObjects: GameObjectSchema[],
    shipId: string
}

export interface API {
    update(data: UpdateData): void
}

const PixiComponent = forwardRef<API, Props>((_, ref) => {

    const widgetContainer = useRef<HTMLDivElement>(null);
    const [gameAPI, setGameAPI] = useState<API>();

    const { gameObjects, shipId, setDirection } = useMic();

    useEffect(() => {
        if (gameAPI) {
            gameAPI.update({ gameObjects, shipId });
        }
    }, [gameAPI, gameObjects, shipId]);

    useImperativeHandle(ref, () => ({
        update(data: UpdateData) {
            if (gameAPI) {
                gameAPI.update(data);
            }
        }
    }));

    useEffect(() => {

        if (widgetContainer.current) {
            const gameConfig: WidgetConfig = {
                container: widgetContainer.current,
                onDirectionChange: setDirection
            }

            const { destroy, update } = createApp(gameConfig)

            setGameAPI({update})

            return () => {
                destroy();
            }
        }

    }, [setDirection]);

    return <div className="admin-map-component">
        <div ref={widgetContainer} style={{ width: '100%', margin: '0 auto', userSelect: 'none' }}></div>
    </div>
})

export default PixiComponent;
