import { useQuery } from "react-query"
import { useMainModuleDispatch, MAIN_MODULE_ACTION, useMainModuleState } from "..";
import Network from "../../network";

export default function useText() {
    const dispatch = useMainModuleDispatch();
    const {user, gameAuthToken} = useMainModuleState();

    const { isFetching, data, error } = useQuery('get-config', Network.getConfig, {
        staleTime: Infinity,
        onSuccess: (data) => {
            dispatch({
                type: MAIN_MODULE_ACTION.FETCH_CONFIG_SUCCESS,
                payload: {
                    user: data.user,
                    gameAuthToken: data.gameAuthToken
                }
            })
        }
    })

    return {
        serverTime: (data?.serverTime || 0)*1000 || Date.now(),
        isLoading: isFetching,
        user,
        error,
        gameAuthToken
    }
}
