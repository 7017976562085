import { useMutation, useQueryClient } from "react-query";
import Network from "../../network";

type ILogin = {
    login: string
}

export default function useLogin() {

    const queryClient = useQueryClient();

    const {mutate, isLoading, error} = useMutation<any, any, ILogin>(({login}) => {
        return Network.login(login)
    }, {
        onSuccess: (data) => {
            queryClient.invalidateQueries('get-config');
            return data;
        }
    });

    return {
        login: mutate,
        isLoading,
        error
    }
}
