import * as PIXI from 'pixi.js';
import { isShip } from '../../../../../modules/server/game-provider';
import { GameObjectSchema } from '../../../../../schemas/GameObjectSchema';
import { ShipSchema } from '../../../../../schemas/ShipSchema';
import { UpdateData } from '../component';
import { WidgetConfig } from '../config';

const GFX_SIZE = 400 * Math.SQRT2;

export default class PixiApp extends PIXI.Application {

    private lines = new PIXI.Graphics();
    private beam = new PIXI.Graphics();
    private ghoust = new PIXI.Graphics();


    private angle = Math.PI / 3;
    private range = 0;
    private direction = Math.PI;

    private ghoustDirection = 0;
    private hasSignal = false;

    private objects = new Map<string, GameObjectSchema>();
    private myShip: ShipSchema | null = null;

    constructor(width: number, height: number, private config: WidgetConfig) {
        super({
            width,
            height,
            backgroundColor: 0x030e32,
            antialias: true
        });

        this.renderer.view.style.width = '100%';

        this.loadAssets([]).then((resources) => {
            this.initWidget();
        })

        this.loop();

    }
    private initWidget() {
        const mainContainer = new PIXI.Container();
        mainContainer.position.set(this.renderer.width / 2, this.renderer.height / 2);

        mainContainer.addChild(this.lines);
        mainContainer.addChild(this.beam);
        mainContainer.addChild(this.ghoust);
        this.stage.addChild(mainContainer);

        this.ghoust.interactive = true;
        this.ghoust.hitArea = this.ghoustGrabPoly;
        this.ghoust.cursor = 'grab';

        this.ghoust.on('pointerdown', (e: PIXI.InteractionEvent) => {
            this.ghoust.cursor = 'grabbing';
            this.ghoustDirection = this.direction;
            this.isGrabbing = true;

            const grabPosition = e.data.getLocalPosition(this.ghoust);
            const grabAngle = Math.atan2(grabPosition.x, -grabPosition.y);

            this.dGrab = this.direction - grabAngle;

            while (this.dGrab >= Math.PI) {
                this.dGrab -= Math.PI * 2
            }

            while (this.dGrab < -Math.PI) {
                this.dGrab += Math.PI * 2
            }
        });

        this.ghoust.on('pointermove', (e: PIXI.InteractionEvent) => {
            if (this.isGrabbing) {
                const grabPosition = e.data.getLocalPosition(this.ghoust);
                let grabAngle = Math.atan2(grabPosition.x, -grabPosition.y);

                while (grabAngle >= Math.PI * 2) {
                    grabAngle -= Math.PI * 2
                }

                while (grabAngle < 0) {
                    grabAngle += Math.PI * 2
                }

                this.ghoustDirection = grabAngle + this.dGrab
            }
        })

        this.ghoust.on('pointerup', () => {
            this.ghoust.cursor = 'grab';
            this.isGrabbing = false;
            // this.direction = this.ghoustDirection;

            this.config.onDirectionChange(this.ghoustDirection);
        });

        this.ghoust.on('pointerout', () => {
            this.ghoust.cursor = 'grab';
            this.isGrabbing = false;
        });
    }

    private dGrab = 0;

    private isGrabbing = false;

    private loadAssets(assets: any[]) {

        return new Promise(resolve => {
            assets.forEach(assetInfo => {
                //@ts-ignore
                this.loader.add(...assetInfo);
            })

            this.loader.load((loader, resource) => {
                resolve(null);
            })

            this.loader.onError.add((...args: any[]) => {
                console.error(...args);
            })
        })

    }

    private beamPoly = new PIXI.Polygon();
    private ghoustPoly = new PIXI.Polygon();
    private ghoustGrabPoly = new PIXI.Polygon();

    public update(data: UpdateData) {
        const existing = new Set(this.objects.keys());

        data.gameObjects.forEach(d => {
            const object = this.objects.get(d.id);


            if (object) {
                existing.delete(d.id);
            } else {
                this.objects.set(d.id, d);

                if (isShip(d) && data.shipId === d.id) {
                    this.myShip = d;

                    this.direction = this.myShip.mic.direction;
                    this.myShip.mic.listen('direction', d => {
                        this.direction = d;
                    });

                    this.range = this.myShip.mic.range;
                    this.myShip.mic.listen('range', r => {
                        this.range = r;
                    });

                    this.angle = this.myShip.mic.angle;
                    this.myShip.mic.listen('angle', a => {
                        this.angle = a;
                    });
                }
            }
        })

        existing.forEach(id => {
            this.remove(id);
        })
    }

    private remove(id: string) {
        this.objects.delete(id);
        if (this.myShip?.id === id) {
            this.myShip = null;
        }
    }

    private drawBeam() {
        this.beam.clear();


        if (!this.hasSignal) {
            this.beam.beginFill(0x00ffff);
        } else {
            this.beam.beginFill(0x00ff00);
        }

        const startAngle = this.direction - this.angle / 2;
        const endAngle = this.direction + this.angle / 2;

        this.beamPoly.points = [
            0, 0,
            GFX_SIZE * Math.sin(startAngle), GFX_SIZE * -Math.cos(startAngle),
            GFX_SIZE * Math.sin(this.direction), GFX_SIZE * -Math.cos(this.direction),
            GFX_SIZE * Math.sin(endAngle), GFX_SIZE * -Math.cos(endAngle),
            0, 0
        ];

        this.beam.drawPolygon(this.beamPoly);

        this.beam.endFill();
    }

    private drawGoust() {
        this.ghoust.clear();

        if (!this.isGrabbing) {
            this.ghoustDirection = this.direction
        }

        const startAngle = this.ghoustDirection - this.angle / 2;
        const endAngle = this.ghoustDirection + this.angle / 2;
        this.ghoustPoly.points = [
            0, 0,
            GFX_SIZE * Math.sin(startAngle), GFX_SIZE * -Math.cos(startAngle),
            GFX_SIZE * Math.sin(this.ghoustDirection), GFX_SIZE * -Math.cos(this.ghoustDirection),
            GFX_SIZE * Math.sin(endAngle), GFX_SIZE * -Math.cos(endAngle),
            0, 0
        ];

        const grabAngle = Math.max(Math.PI/4, this.angle);

        this.ghoustGrabPoly.points = [
            0, 0,
            GFX_SIZE * Math.sin(this.ghoustDirection - grabAngle / 2), GFX_SIZE * -Math.cos(this.ghoustDirection - grabAngle / 2),
            GFX_SIZE * Math.sin(this.ghoustDirection), GFX_SIZE * -Math.cos(this.ghoustDirection),
            GFX_SIZE * Math.sin(this.ghoustDirection + grabAngle / 2), GFX_SIZE * -Math.cos(this.ghoustDirection + grabAngle / 2),
            0, 0
        ];


        if (this.isGrabbing) {
            this.ghoust.lineStyle(1, 0xffffff, 1);
            this.ghoust.drawPolygon(this.ghoustPoly);
        }
    }

    loop() {
        requestAnimationFrame(() => {
            this.draw();
            this.loop();
        });
    }

    draw() {
        this.hasSignal = this.checkSignal();
        this.drawBeam();
        this.drawGoust();
    }

    private checkSignal() {
        if (!this.myShip) {
            return false;
        }

        let result = false;
        const range2 = this.range * this.range;

        this.objects.forEach(obj => {
            if (!this.myShip || result) return;
            if (this.myShip.id === obj.id) return;
            if (!obj.noise.active) return;

            const dx = obj.x - this.myShip.x;
            const dy = obj.y - this.myShip.y;

            const dist2 = dx * dx + dy * dy;

            if (dist2 > range2) return;

            let objAngle = Math.atan2(dx, -dy);

            if (objAngle < 0) {
                objAngle += Math.PI * 2;
            }

            let dAngle = Math.abs(objAngle-this.direction);

            if (dAngle > Math.PI) {
                dAngle = Math.PI*2 - dAngle;
            }

            if (dAngle <= this.angle/2) {
                result = true;
            }

        });

        return result;
    }

    public destroy(removeView: boolean = false) {
        super.destroy(removeView)
    }
}
