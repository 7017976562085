import { useMemo } from "react";
import useMySlot from "./use-my-slot";

export default function useMyPanels() {
    const mySlot = useMySlot();
    return useMemo(() => {
        if (mySlot) {
            if (mySlot.panels.length === 0) {
                return [
                    "radar",
                    "main-engine",
                    "object-info",
                    "scanner",
                    "tracking-list",
                    'reactor',
                    'status',
                    'torpedos',
                    'torpedo-controll',
                    'decoys',
                    'shields',
                    'tractor-beam',
                    'cargo',
                    'mission-coms',
                    'energy-radar',
                    'mic',
                    'crew',
                    'v2-controlls'
                ]
            } else {
                return mySlot.panels
            }
        }

        return [];
    }, [mySlot])
}
