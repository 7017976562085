import { useCallback, useMemo } from "react";
import { useGame } from "../../..";
import { ShipSchema } from "../../../../../schemas/ShipSchema";

export default function useCrew(ship: ShipSchema) {
    const { playerSlots, users, room } = useGame();

    const crew = useMemo(() => {
        return users.filter(u => u.shipId === ship.id);
    }, [users, ship])

    const slots = useMemo(() => {
        return playerSlots.filter(s => s.shipId === ship.id);
    }, [playerSlots, ship])

    const updateCrew = useCallback((data) => {
        if (room) {
            room.send('update-crew', {
                ...data
            })
        }
    }, [room])

    return {
        crew,
        slots,
        updateCrew
    }
}
