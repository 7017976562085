import { useParams } from "react-router";
import { GameAdminProvider, GameProvider, LocalStateProvider } from "../../modules/server";
import GameAdmin from "./game-admin";

export default function GameAdminPage() {

    const { id } = useParams<{ id: string }>();

    return <GameProvider id={id}>
        <LocalStateProvider>
            <GameAdminProvider id={id}>
                <GameAdmin />
            </GameAdminProvider>
        </LocalStateProvider>
    </GameProvider>;
}
