
import * as SAT from 'sat';

export default class RaycastResult {
    pointsCount: number = 0;
    points: SAT.Vector[] = [];

    reset() {
        this.points.length = 0;
        this.pointsCount = 0;
    }

}
