import styled from "styled-components"
import useLobby from "../../modules/server/hooks/server-lobby/use-lobby";
import OpenGame from "./open-game";

type Props = {}

const Container = styled.div`
    h2 {
        padding-bottom: .5em;
        border-bottom: 1px solid black;
        width: 100%;
        text-align: center;
    }

    .row {
        width: 100%;
        margin: 0.5em;
    }

    .controlls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .list {
        padding: 1em;
        background: #d9d9d9;
    }

    .empty {
        color: #5e5e5e;
        margin: 1em;
        border: 1px dashed #5e5e5e;
        padding: 1em;
        text-align: center;
    }
`;

const OpenGamesList: React.FC<Props> = () => {
    const { rooms, create, refresh, join } = useLobby();

    return <Container className="box">
        <h2>Lista gier czekających na rozpoczęcie</h2>
        <div className="row controlls">
            <div>
                <button onClick={create}>Nowa gra</button>
            </div>
            <button onClick={refresh}>odświerz</button>
        </div>
        <div className="row">
            <div className="list">
                {rooms.length > 0 && rooms.map(r => {
                    return <OpenGame room={r} joinHandler={join} key={r.roomId} />
                })}
                {!rooms.length && <div className="empty">Brak gier</div>}
            </div>
        </div>
    </Container>
};

export default OpenGamesList;
