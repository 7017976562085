import CONFIG from "../config";
import createGameAdminProvider from "./game-admin-provider";
import createGameLobbyProvider from "./game-lobby-provider";
import createGameProvider from "./game-provider";
import createMyShipProvider from "./hooks/game/my-ship-provider";
import createLocalStateProvider from "./local-state-provider";
import createProvider from "./provider";

const url = CONFIG.GAME_SERVER_URL;

const {
    ServerProvider,
    useServer
} = createProvider(url)

const {
    GameLobbyProvider,
    useGameLobby
} = createGameLobbyProvider();

const {
    GameProvider,
    useGame
} = createGameProvider();

const {
    GameAdminProvider,
    useGameAdmin
} = createGameAdminProvider();

const {
    LocalStateProvider,
    useLocalState
} = createLocalStateProvider();

const {
    MyShipProvider,
    useMyShip
} = createMyShipProvider();



export {
    ServerProvider,
    useServer,


    GameLobbyProvider,
    useGameLobby,

    GameProvider,
    useGame,

    GameAdminProvider,
    useGameAdmin,

    LocalStateProvider,
    useLocalState,

    MyShipProvider,
    useMyShip
}
