import { useCallback } from "react";
import { useGameLobby } from "../..";

export default function useGameLobbyRules() {
    const {room, rules} = useGameLobby();

    const setRuleValue = useCallback((key: string, value: number) => {
        if (room) {
            room.send('set-rule-value', {
                key,
                value
            })
        }
    }, [room]);

    return {
        setRuleValue,
        rules
    }
}
