import { privateAPI } from "./component";
import { WidgetConfig } from "./config";
import PixiApp, { UpdateData } from "./app";

const BASE_WIDTH = 800;
const BASE_HEIGHT = 600;

export default function createApp(config: WidgetConfig): (privateAPI & {destroy(): void}) {

    const app = new PixiApp(BASE_WIDTH, BASE_HEIGHT, config);

    config.container.appendChild(app.view);

    return {
        destroy: () => {
            app.destroy(true);
        },
        update: (data: UpdateData) => {
            app.update(data)
        },
        zoomIn: () => {
            app.zoomIn();
        },
        zoomOut: () => {
            app.zoomOut();
        },
        zoomReset: () => {
            app.zoomReset();
        },
        moveUp: () => {
            app.moveUp();
        },
        moveDown: () => {
            app.moveDown();
        },
        moveLeft: () => {
            app.moveLeft();
        },
        moveRight: () => {
            app.moveRight();
        },
        centerView: () => {
            app.centerView();
        },
        setCenter: (id: string) => {
            app.centerOn(id);
        }
    }
}
