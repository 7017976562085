import {Client} from 'colyseus.js';
import React, { useRef } from 'react';
import { useContext } from 'react';

export default function createProvider(url: string) {
    const gameServer = new Client(url);

    const context = React.createContext<{
        gameServer: Client
    }>({gameServer});

    const ServerProvider: React.FC<{token: string | undefined}> = ({children, token}) => {
        const server = useRef(gameServer);
        if (token) {
            gameServer.auth.token = token;
        }

        return (
            <context.Provider value={{
                gameServer: server.current
            }}>
                {children}
                <p>Token: {token}</p>
            </context.Provider>
        )
    }

    const useServer = () => {
        return useContext(context);
    }

    return {
        ServerProvider,
        useServer
    }
}
