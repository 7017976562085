import React, { useContext } from "react";
import { useGame } from "../..";
import { ShipSchema } from "../../../../schemas/ShipSchema";
import useGameObject from "./use-game-object";

interface MyShipData {
    ship?: ShipSchema,
    x: number,
    y: number
}

export default function createMyShipProvider() {

    const context = React.createContext<MyShipData>({
        x: 0,
        y: 0
    });

    const MyShipProvider: React.FC<{}> = ({ children }) => {


        const { shipId } = useGame();
        const data = useGameObject(shipId);

        return (
            <context.Provider value={{
                ship: data.gameObject as ShipSchema,
                ...data
            }}>
                {children}
            </context.Provider>
        )
    }

    const useMyShip = () => {
        return useContext(context);
    }


    return {
        MyShipProvider,
        useMyShip
    }
}
