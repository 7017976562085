import PixiApp from "./app";
import { API, UpdateData } from "./component";
import { WidgetConfig } from "./config";

const BASE_WIDTH = 400;
const BASE_HEIGHT = 400;

export default function createApp(config: WidgetConfig): (API & {destroy(): void}) {

    const app = new PixiApp(BASE_WIDTH, BASE_HEIGHT, config);

    config.container.appendChild(app.view);

    return {
        destroy: () => {
            app.destroy(true);
        },
        update: (data: UpdateData) => {
            app.update(data);
        }
    }
}
