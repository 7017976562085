import { Link } from "react-router-dom";
import { getGameRoute, getServerLobbyRoute } from "../routes";
import ScenarioForm from "./scenario-form";
import { useGameLobby } from "../../modules/server";
import styled from "styled-components";
import UserInfo from "../../components/user-info";
import UsersInRoom from "./users-in-room";
import ScenarioSelectForm from "./scenario-select";
import { useForm } from "react-hook-form";
import RulesForm from "./rules-form";

type Props = {

}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .row {
        margin: 0.5em;
        width: 95%;
    }

    .header {
        display: flex;
        flex-direction: row;

        h1 {
            flex: 100% 1 1;
            text-align: center;
        }
    }

    .controlls {
        background: #d9d9d9;

        .inner {
            padding: 1em;
            display: flex;
            justify-content: space-between;
        }
    }

    .boxes {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;

        &>* {
            flex: 45% 0 0;
            padding: 2em;
        }
    }
`;

const GameLobby: React.FC<Props> = () => {

    const { id, state, isScenarioSelected, leave, isGameInProgress, startGame, gameRoomId } = useGameLobby();

    const { register, watch } = useForm();

    const userType = watch('userType', 'player');
    const showAdmin = userType === 'admin';

    return <Container>
        <div className="row header">
            <h1>Game lobby</h1>
            <UserInfo />
        </div>
        <div className="row controlls">
            <div className="inner">
                <p>
                    Gra: {id}
                </p>
                <div>Kim jesteś?
                    <label>
                        Gracz <input type="radio" value="player" {...register('userType')} />
                    </label>
                    <label>
                        Administrator <input type="radio" value="admin" {...register('userType')} />
                    </label>
                </div>
                <Link onClick={leave} to={getServerLobbyRoute()}>Wyjdź</Link>
            </div>
        </div>
        {state && <>
            <div className="row status">
                <div className="box">
                    <div>
                        Status gry: {isGameInProgress ? 'W TRAKCIE' : 'CZEKA NA ROZPOCZĘCIE'}
                    </div>

                    {!isGameInProgress && isScenarioSelected && <button onClick={startGame}>START GAME!</button>}
                    {isGameInProgress && <Link to={getGameRoute(gameRoomId)}>Wejdź do gry</Link>}

                </div>
            </div>
            <div className="row boxes">
                <div className="inner">
                    <div className="box">
                        {
                            isScenarioSelected && <ScenarioForm gameId={id} />
                        }
                        {
                            !isScenarioSelected && <ScenarioSelectForm />
                        }
                    </div>
                </div>
                <div className="inner">
                    <div className="box">
                        <UsersInRoom users={state.users} />
                    </div>
                </div>
            </div>
            <div className="row">
                {showAdmin && isScenarioSelected && <RulesForm />}
            </div>
        </>}
        {!state && <p>Ładowanie</p>}
    </Container>

}



export default GameLobby
