import { useEffect } from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import useReactor from "../../../modules/server/hooks/game/panels/use-reactor"
import UIPanel from "../panel"

type Props = {

}

const UIReactor: React.FC<Props> = () => {

    const {
        power, freePower, mainEngine, setReactor,
        radar, energyRadar, microphone, storage,
        torpedos, shields, tracking, scaner,
        mainEngineHealth,
        radarHealth, energyRadarHealth, microphoneHealth,
        storageHealth, torpedosHealth, shieldsHealth, trackingHealth, scanerHealth,
    } = useReactor()

    const formMethods = useForm();
    const { setValue, watch } = formMethods;

    useEffect(() => {
        setValue('mainEngine', `${mainEngine}`);
        setValue('radar', `${radar}`);
        setValue('energyRadar', `${energyRadar}`);
        setValue('microphone', `${microphone}`);
        setValue('storage', `${storage}`);
        setValue('torpedos', `${torpedos}`);
        setValue('shields', `${shields}`);
        setValue('tracking', `${tracking}`);
        setValue('scaner', `${scaner}`);
    }, [
        mainEngine,
        radar, energyRadar, microphone, storage,
        torpedos, shields, tracking, scaner, setValue
    ]);

    const _mainEngine = watch('mainEngine');
    const _radar = watch('radar');
    const _energyRadar = watch('energyRadar');
    const _microphone = watch('microphone');
    const _storage = watch('storage');
    const _torpedos = watch('torpedos');
    const _shields = watch('shields');
    const _tracking = watch('tracking');
    const _scaner = watch('scaner');

    useEffect(() => {
        const value = parseInt(_mainEngine);

        if (!isNaN(value) && value !== mainEngine) {
            setReactor('mainEngine', value);
        }

    }, [_mainEngine, mainEngine, setReactor]);

    useEffect(() => {
        const value = parseInt(_radar);

        if (!isNaN(value) && value !== radar) {
            setReactor('radar', value);
        }

    }, [_radar, radar,  setReactor]);

    useEffect(() => {
        const value = parseInt(_energyRadar);

        if (!isNaN(value) && value !== energyRadar) {
            setReactor('energyRadar', value);
        }

    }, [_energyRadar, energyRadar, setReactor]);

    useEffect(() => {
        const value = parseInt(_microphone);

        if (!isNaN(value) && value !== microphone) {
            setReactor('microphone', value);
        }

    }, [_microphone, microphone, setReactor]);

    useEffect(() => {
        const value = parseInt(_storage);

        if (!isNaN(value) && value !== storage) {
            setReactor('storage', value);
        }

    }, [_storage, storage, setReactor]);

    useEffect(() => {
        const value = parseInt(_torpedos);

        if (!isNaN(value) && value !== torpedos) {
            setReactor('torpedos', value);
        }

    }, [_torpedos, torpedos, setReactor]);

    useEffect(() => {
        const value = parseInt(_shields);

        if (!isNaN(value) && value !== shields) {
            setReactor('shields', value);
        }

    }, [_shields, shields, setReactor]);

    useEffect(() => {
        const value = parseInt(_tracking);

        if (!isNaN(value) && value !== tracking) {
            setReactor('tracking', value);
        }

    }, [_tracking, tracking, setReactor]);

    useEffect(() => {
        const value = parseInt(_scaner);

        if (!isNaN(value) && value !== scaner) {
            setReactor('scaner', value);
        }

    }, [_scaner, scaner, setReactor]);

    return <UIPanel title="Reactor">
        <p>
            Moc reactora: {freePower} / {power}<br />
            {
                Array(power).fill(0).map((_, index) => {
                    return <input type='radio' key={index} disabled={true} checked={(index <= (freePower || 0)) || false} />
                })
            }
        </p>
        <FormProvider {...formMethods} >
            <form>
                <table>
                    <thead>
                        <tr>
                            <td>System</td>
                            <td>0</td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                        </tr>
                    </thead>
                    <tbody>
                        <TableRow health={mainEngineHealth || 0} freePower={freePower || 0} id={'mainEngine'} value={mainEngine || 0} >Silnik główny</TableRow>
                        <TableRow health={radarHealth || 0} freePower={freePower || 0} id={'radar'} value={radar || 0} >Radar</TableRow>
                        <TableRow health={energyRadarHealth || 0} freePower={freePower || 0} id={'energyRadar'} value={energyRadar || 0} >Radar energetyczny</TableRow>
                        <TableRow health={microphoneHealth || 0} freePower={freePower || 0} id={'microphone'} value={microphone || 0} >Mikrofon kierunkowy</TableRow>
                        <TableRow health={storageHealth || 0} freePower={freePower || 0} id={'storage'} value={storage || 0} >Ładownia</TableRow>
                        <TableRow health={torpedosHealth || 0} freePower={freePower || 0} id={'torpedos'} value={torpedos || 0} >Torpedy</TableRow>
                        <TableRow health={shieldsHealth || 0} freePower={freePower || 0} id={'shields'} value={shields || 0} >Tarcze</TableRow>
                        <TableRow health={trackingHealth || 0} freePower={freePower || 0} id={'tracking'} value={tracking || 0} >Śledzenie</TableRow>
                        <TableRow health={scanerHealth || 0} freePower={freePower || 0} id={'scaner'} value={scaner || 0} >Skaner {scaner}</TableRow>
                    </tbody>
                </table>
            </form>
        </FormProvider>
    </UIPanel>
}

export default UIReactor;


const TableRow: React.FC<{
    freePower: number,
    value: number,
    id: string,
    health: number
}> = ({ children, freePower, value, id, health }) => {
    const { register } = useFormContext();

    return <tr>
        <td>
            {children}
        </td>
        <td style={{ background: health < 0 ? 'red' : '' }}><input type="radio" value={0} disabled={((freePower) + (value) - 0 < 0) || (health < 0)} {...register(id)} /></td>
        <td style={{ background: health < 1 ? 'red' : '' }}><input type="radio" value={1} disabled={((freePower) + (value) - 1 < 0) || (health < 1)} {...register(id)} /></td>
        <td style={{ background: health < 2 ? 'red' : '' }}><input type="radio" value={2} disabled={((freePower) + (value) - 2 < 0) || (health < 2)} {...register(id)} /></td>
        <td style={{ background: health < 3 ? 'red' : '' }}><input type="radio" value={3} disabled={((freePower) + (value) - 3 < 0) || (health < 3)} {...register(id)} /></td>
    </tr>
}
