import { useMemo } from "react";
import useShipStatus from "../../../modules/server/hooks/game/panels/use-status";
import useBasicSchema from "../../../modules/server/hooks/use-basic-schema";
import { RepairInProgressSchema } from "../../../schemas/RepairInProgressSchema";
import UIPanel from "../panel"

type Props = {}

const UIShipStatus: React.FC<Props> = () => {

    const {
        hull, mainEngine, maneveurEngine,
        hiperEngine, radar, energyRadar, microphone, storage,
        torpedos, shields, tracking, scaner,
        sendCrew, availableCrew, repairsInProgress
    } = useShipStatus();

    return <UIPanel title="Stan statku">
        <p>
            Wytrzymałość kadłuba: {hull}<br />
            Ekipy remontowe: {availableCrew}
        </p>
        <table>
            <thead>
                <tr>
                    <td>System</td>
                    <td>Stan</td>
                    <td>Naprawa</td>
                </tr>
            </thead>
            <tbody>
                <TableRow sendRepairCrew={sendCrew} repairsInProgress={repairsInProgress} value={mainEngine || 0} availableCrew={availableCrew} id='mainEngine'>
                    Silnik główny
                </TableRow>

                <TableRow sendRepairCrew={sendCrew} repairsInProgress={repairsInProgress} value={maneveurEngine || 0} availableCrew={availableCrew} id='maneveurEngine'>
                    Silniki manewrowe {maneveurEngine}
                </TableRow>
                <TableRow sendRepairCrew={sendCrew} repairsInProgress={repairsInProgress} value={hiperEngine || 0} availableCrew={availableCrew} id='hiperEngine'>
                    Hipernapęd {hiperEngine}
                </TableRow>
                <TableRow sendRepairCrew={sendCrew} repairsInProgress={repairsInProgress} value={radar || 0} availableCrew={availableCrew} id='radar'>
                    Radar {radar}
                </TableRow>
                <TableRow sendRepairCrew={sendCrew} repairsInProgress={repairsInProgress} value={energyRadar || 0} availableCrew={availableCrew} id='energyRadar'>
                    Radar energetyczny {energyRadar}
                </TableRow>
                <TableRow sendRepairCrew={sendCrew} repairsInProgress={repairsInProgress} value={microphone || 0} availableCrew={availableCrew} id='microphone'>
                    Mikrofon kierunkowy {microphone}
                </TableRow>
                <TableRow sendRepairCrew={sendCrew} repairsInProgress={repairsInProgress} value={storage || 0} availableCrew={availableCrew} id='storage'>
                    Ładownia {storage}
                </TableRow>
                <TableRow sendRepairCrew={sendCrew} repairsInProgress={repairsInProgress} value={torpedos || 0} availableCrew={availableCrew} id='torpedos'>
                    Torpedy {torpedos}
                </TableRow>
                <TableRow sendRepairCrew={sendCrew} repairsInProgress={repairsInProgress} value={shields || 0} availableCrew={availableCrew} id='shields'>
                    Tarcze {shields}
                </TableRow>
                <TableRow sendRepairCrew={sendCrew} repairsInProgress={repairsInProgress} value={tracking || 0} availableCrew={availableCrew} id='tracking'>
                    Śledzenie {tracking}
                </TableRow>
                <TableRow sendRepairCrew={sendCrew} repairsInProgress={repairsInProgress} value={scaner || 0} availableCrew={availableCrew} id='scaner'>
                    Skaner {scaner}
                </TableRow>

            </tbody>
        </table>
    </UIPanel >
}

export default UIShipStatus;


const TableRow: React.FC<{
    value: number,
    sendRepairCrew(system: string): void,
    availableCrew: number,
    repairsInProgress: RepairInProgressSchema[],
    id: string
}> = ({ children, value, availableCrew: avaiableCrews, sendRepairCrew, repairsInProgress, id }) => {

    const repairData = useMemo(() => {
        return repairsInProgress.find(r => r.system === id)
    }, [repairsInProgress, id])

    const progress = useBasicSchema<RepairInProgressSchema, number>(repairData, 'progress');

    const showRepairProgress = Boolean(repairData);
    const showRepairButton = !showRepairProgress && (value < 3);

    return <tr>
        <td>
            {children}
        </td>
        <td>{value}/3</td>
        <td>
            {
                showRepairButton && <button disabled={avaiableCrews <= 0} onClick={() => sendRepairCrew(id)}>Wyślij ekipę!</button>
            }
            {
                showRepairProgress && <progress max={100} value={(progress || 0) * 100}></progress>
            }
        </td>
    </tr>
}
