import { SetSchema } from '@colyseus/schema';
import { useReducer, useEffect, useCallback } from 'react';

type Action<T> = {
    type: 'init',
    data: SetSchema<T>
} | {
    type: 'add' | 'remove',
    data: T
}

export default function useSetSchema<T>(schema?: SetSchema<T>) {
    const [value, change] = useReducer((state: T[], action: Action<T>) => {

        if (action.type === 'init') {
            const result: T[] = [];

            action.data.forEach(p => {
                result.push(p);
            })

            return result;
        }

        if (action.type === 'add') {

            return Array.from(new Set([
                ...state,
                action.data
            ]));
        }

        if (action.type === 'remove') {
            return state.filter(i => i !== action.data);
        }

        return state;
    }, []);

    const onAdd = useCallback((item) => {
        change({ type: 'add', data: item })
    }, [change]);

    const onRemove = useCallback((item) => {
        change({ type: 'remove', data: item })
    }, [change]);

    useEffect(() => {
        if (schema) {



            change({ type: 'init', data: schema });

            //@ts-ignore
            if (!schema._onAdd && !schema._onRemove) {
                //@ts-ignore
                schema._onAdd = new Set();

                //@ts-ignore
                schema._onRemove = new Set();
            }

            //@ts-ignore
            schema._onAdd.add(onAdd);
            //@ts-ignore
            schema._onRemove.add(onRemove);


            schema.onAdd = (item) => {
                //@ts-ignore
                schema._onAdd.forEach(fn => {
                    fn(item);
                })
            }

            schema.onRemove = (item) => {
                //@ts-ignore
                schema._onRemove.forEach(fn => {
                    fn(item);
                })
            }

            return () => {
                //@ts-ignore
                schema._onAdd.delete(onAdd);
                //@ts-ignore
                schema._onRemove.delete(onRemove);
            }
        }
    }, [schema, onAdd, onRemove]);

    return value;
}
