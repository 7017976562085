import { useCallback } from "react";
import { useGame, useMyShip } from "../../..";
import { CargoContentSchema } from "../../../../../schemas/CargoContentSchema";
import { CargoSchema } from "../../../../../schemas/CargoSchema";
import useBasicSchema from "../../use-basic-schema";
import useMapSchema from "../../use-map-schema";

export default function useCargo() {
    const { ship } = useMyShip();
    const { room } = useGame();

    const cargo = useMapSchema<CargoContentSchema>(ship?.cargo.content);
    const isDocked = useBasicSchema<CargoSchema, boolean>(ship?.cargo, 'isDocked');

    const loadOut = useCallback((type: string) => {
        if (room) {
            room.send('load-out', {
                type
            })
        }
    }, [room]);

    return {
        cargo,
        isDocked,
        loadOut
    }
}
