import { Room } from "colyseus.js";
import React, { useContext, useMemo, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { useServer } from ".";
import { getServerLobbyRoute } from "../../routes/routes";
import { AreaSchema } from "../../schemas/AreaSchema";
import { ContainerSchema } from "../../schemas/ContainerSchema";
import { GameObjectSchema } from "../../schemas/GameObjectSchema";
import { GameRoomSchema } from "../../schemas/GameRoomSchema";
import { GameUserSchema } from "../../schemas/GameUserSchema";
import { PlayerSlotSchema } from "../../schemas/PlayerSlotSchema";
import { RulesSchema } from "../../schemas/RulesSchema";
import { ShipSchema } from "../../schemas/ShipSchema";
import { TorpedoInSpaceSchema } from "../../schemas/TorpedoInSpaceSchema";
import { TorpedoTypeSchema } from "../../schemas/TorpedoTypeSchema";
import useConfig from "../main/hooks/use-config";
import useMapSchema from "./hooks/use-map-schema";

type GameData = {
    state: GameRoomSchema | null,
    shipId: string,
    gameObjects: GameObjectSchema[],
    ships: ShipSchema[],
    areas: AreaSchema[],
    room?: Room<GameRoomSchema>,
    isConnected: boolean,
    torpedos: TorpedoInSpaceSchema[],
    containers: ContainerSchema[]
    torpedoTypes: TorpedoTypeSchema[],
    damageState: string,
    rules?: RulesSchema,
    users: GameUserSchema[]
    playerSlots: PlayerSlotSchema[]
}

export default function createGameProvider() {
    const context = React.createContext<GameData>({
        state: null,
        shipId: '',
        gameObjects: [],
        ships: [],
        areas: [],
        isConnected: false,
        torpedos: [],
        torpedoTypes: [],
        containers: [],
        damageState: '',
        users: [],
        playerSlots: []
    });

    const GameProvider: React.FC<{ id: string }> = ({ children, id }) => {

        const { gameServer } = useServer();
        const history = useHistory();
        const { user } = useConfig();
        const [room, setRoom] = useState<Room<GameRoomSchema>>();


        const state: GameRoomSchema | null = useMemo(() => {
            if (room) {
                //@ts-ignore
                window['STATE'] = room.state;

                // room.onStateChange((state => {
                //     console.log('new state:', state, DEV_A++);
                // }))

                return room.state;
            }

            return null;
        }, [room]);

        const [shipId, setShipId] = useState<string>('');
        const [isConnected, setIsConnected] = useState<boolean>(false);

        const gameObjects = useMapSchema<GameObjectSchema>(state?.game.gameObjects);
        const areas = useMapSchema<AreaSchema>(state?.game.areas);
        const torpedoTypes = useMapSchema<TorpedoTypeSchema>(state?.game.torpedoTypes);
        const users = useMapSchema<GameUserSchema>(state?.users);
        const playerSlots = useMapSchema<PlayerSlotSchema>(state?.playerSlots);

        const playerShips = useMemo(() => {
            return gameObjects.filter(isShip)
        }, [gameObjects]);

        const torpedos = useMemo(() => {
            return gameObjects.filter(isTorpedo)
        }, [gameObjects]);

        const containers = useMemo(() => {
            return gameObjects.filter(isContainer)
        }, [gameObjects]);

        // W przypadku odtrzymania obrażeń ustrawiamy na 1s. klase CSS która pozwala dodać animację
        const [damageState, setDamageState] = useState('');

        useEffect(() => {
            if (damageState) {
                const id = window.setTimeout(() => {
                    setDamageState('')
                }, 1000);

                return () => {
                    clearTimeout(id);
                }
            }
        }, [damageState, setDamageState])

        useEffect(() => {
            gameServer.joinById<GameRoomSchema>(id)
                .then(room => {
                    setRoom(room);

                    room.onStateChange(() => {
                        setIsConnected(true);
                    })

                    room.onMessage('hull-damaged', (msg) => {
                        setDamageState('hull-damaged')
                    });

                    room.onMessage('shield-downed', (msg) => {
                        setDamageState('shield-downed')
                    });

                    room.onMessage('shield-damaged', (msg) => {
                        setDamageState('shield-damaged')
                    });
                })
                .catch(() => {
                    history.push(getServerLobbyRoute());
                });
        }, [gameServer, id, history, setIsConnected, setDamageState]);

        useEffect(() => {
            return () => {
                room?.leave();
            }
        }, [room])

        useEffect(() => {
            if (state) {

                state.listen('users', (value) => {
                    const userData = state.users.get(user.id);

                    if (userData) {
                        setShipId(userData.shipId);
                    }
                });

            }
        }, [state, user]);

        const data: GameData = {
            state,
            shipId,
            gameObjects,
            ships: playerShips,
            areas,
            room,
            isConnected,
            torpedos,
            torpedoTypes,
            containers,
            damageState,
            rules: state?.game.rules,
            users,
            playerSlots
        };

        return (
            <context.Provider value={data}>
                {children}
            </context.Provider>
        )
    }

    const useGame = () => {
        return useContext(context);
    }

    return {
        GameProvider,
        useGame
    }
}


export function isShip(go: GameObjectSchema): go is ShipSchema {
    return go.type === 'ship';
}

export function isTorpedo(go: GameObjectSchema): go is TorpedoInSpaceSchema {
    return go.type === 'torpedo';
}

function isContainer(go: GameObjectSchema): go is ContainerSchema {
    return go.type === 'container';
}

// function isDecoy(go: GameObjectSchema): go is DecoySchema {
//     return go.type === 'decoy';
// }
