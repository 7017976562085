import React from "react";
import styled from "styled-components"

type Props = {
    title: string,
    style?: React.CSSProperties
    panelStyle?: React.CSSProperties
}

const Container = styled.section`
    border: 1px solid black;
    border-radius: 17px;
    height: 100%;
    overflow: hidden;

    color: #0c0a32;
    font-family: 'Jura', sans-serif;

    display: flex;
    flex-direction: column;
    background: white;
    background-image: linear-gradient(0deg, rgba(0,0,0,0.28) 0%, rgba(255,255,255,0.28) 100%);

    header {
        border-bottom: 1px solid #9d9d9d;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 24px;
    }

    .move-handle {
        cursor: move;
    }

    .ui-panel-body {
        flex: 1 1 100%;
        overflow: auto;
    }
`;

const UIPanel: React.FC<Props> = ({ children, title, style, panelStyle }) => {
    return <Container style={style}>
        <header className="move-handle">
            {title || ''}
        </header>
        <div className="ui-panel-body" style={panelStyle}>
            {children}
        </div>
    </Container>
}

export default UIPanel;
