import styled from "styled-components";
import { LobbyUserSchema } from "../../schemas/LobbyUserSchema";
import { MapSchema } from '@colyseus/schema';
import useMapSchema from "../../modules/server/hooks/use-map-schema";

type Props = {
    users: MapSchema<LobbyUserSchema>
}

const Container = styled.div`
    width: 100%;

    h2 {
        padding-bottom: .5em;
        border-bottom: 1px solid black;
        width: 100%;
        text-align: center;
    }

    ul {
        margin: 1em 0;
        list-style: none;
        padding-inline-start: 0;
    }

    li {
        background: #d9d9d9;
        padding: .5em;
        margin: 0;
    }
`;

const UsersInRoom: React.FC<Props> = (props) => {

    const users = useMapSchema(props.users);

    return <Container>
        <h2>Gracze w pokoju</h2>
        <ul>
            {users.map(p => {
                return <li key={p.id}>{p.nickname}</li>
            })}
        </ul>
    </Container>
}

export default UsersInRoom;
