import { useMemo } from "react";
import { useForm } from "react-hook-form";
import useTorpedos from "../../../modules/server/hooks/game/panels/use-torpedos";
import UIPanel from "../panel"
import TorpedoControlls from "./controlls";

type Props = {}

const UITorpedoControll: React.FC<Props> = () => {

    const { myActiveTorpedos } = useTorpedos();

    const { register, watch } = useForm();

    const torpedoId = watch('torpedoId')

    const showControlls = useMemo(() => {
        return Boolean(myActiveTorpedos.find(t => t.id === torpedoId));
    }, [myActiveTorpedos, torpedoId])

    return <UIPanel title="Kontrola torped">
        <div>
            <select {...register('torpedoId')}>
                <option value={''}>-- Brak --</option>
                {myActiveTorpedos.map(t => {
                    return <option key={t.id} value={t.id}>{t.id}</option>
                })}
            </select>
        </div>
        <div>
            {showControlls && <TorpedoControlls torpedoId={torpedoId} />}
            {!showControlls && <div>Nie ma czym sterować</div>}
        </div>
    </UIPanel>
}

export default UITorpedoControll;
