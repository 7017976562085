import { RoomAvailable } from "colyseus.js";
import { useCallback, useEffect, useState } from "react";
import { useServer } from "..";

export default function useGameRooms() {
    const { gameServer } = useServer();
    const [rooms, setRooms] = useState<RoomAvailable[]>([]);
    const [inProgressRooms, setInProgressRooms] = useState<RoomAvailable[]>([]);

    const getRooms = useCallback(() => {
        gameServer.getAvailableRooms('game-lobby')
            .then(result => {
                setRooms(result.filter(room => !room.metadata.inProgress));
            });
    }, [gameServer, setRooms])

    const getInProgressRooms = useCallback(() => {
        gameServer.getAvailableRooms('game-room')
            .then(result => {
                setInProgressRooms(result);
            });
    }, [gameServer, setInProgressRooms])

    const refresh = useCallback(() => {
        getRooms();
        getInProgressRooms();
    }, [getRooms, getInProgressRooms])


    useEffect(() => {
        refresh()
    }, [refresh]);

    return {
        rooms,
        inProgressRooms,
        refresh
    }
}
