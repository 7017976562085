import { useCallback } from "react"
import { useGame } from "../../..";

export default function useDecoys() {

    const { room } = useGame();

    const launchDecoy = useCallback((angle: number) => {
        if (room) {
            room.send('launch-decoy', {
                angle: angle * (Math.PI / 180)
            });
        }
    }, [room])

    return {
        launchDecoy
    }
}
