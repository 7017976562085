import { useEffect } from "react";
import { useState, useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";
import styled from "styled-components"
import useTorpedo from "../../../modules/server/hooks/game/panels/use-torpedos";
import useTrakedList from "../../../modules/server/hooks/game/panels/use-tracking-list";
import CourseInput from "../../course-input";
import UIPanel from "../panel"
import TopedosStorage from "./storage";

type Props = {}

const Container = styled.div`

`;

const UITorpedos: React.FC<Props> = () => {

    const formMethods = useForm();
    const { shipTorpedos: torpedos, fireTorpedo, fireOnTarget } = useTorpedo();
    const { watch, register, setValue } = formMethods;
    const { tracked, selectObject, selectedObject } = useTrakedList();

    const torpedoType = watch('torpedoType');
    const targetingMode = watch('targetingMode');

    const [angle, setAngle] = useState(0);

    const onFire = useCallback(() => {
        if (targetingMode === 'angle') {
            fireTorpedo(torpedoType, angle)
        } else if (selectedObject && targetingMode === 'target') {
            fireOnTarget(torpedoType, selectedObject)
        }
    }, [fireTorpedo, torpedoType, targetingMode, angle, selectedObject, fireOnTarget]);

    useEffect(() => {
        setImmediate(() => {
            setValue('torpedoType', torpedos[0]?.type || '');
            setValue('targetingMode', 'angle');
        })
    }, [setValue, torpedos])


    return <UIPanel title="Torpedownia">
        <FormProvider {...formMethods} >

            <Container>
                <div className="storage">
                    <h4>Magazyn</h4>
                    <TopedosStorage torpedos={torpedos} />
                </div>
                <div className="targetingMode">
                    <h4>Tryb celowania</h4>
                    <label><input type='radio' value='angle' {...register('targetingMode')}></input>Wskazany kierunek</label><br />
                    <label><input type='radio' value='target' {...register('targetingMode')}></input>Wyceluj w obiekt</label>
                </div>
                <div className="targeting">
                    <h4>Strzelanie</h4>
                    {targetingMode === 'angle' && <div style={{ position: 'relative', width: "150px" }}>
                        <CourseInput onChange={setAngle} value={angle} />
                    </div>}
                    {
                        targetingMode === 'target' && <div>
                            {tracked.length > 0 && tracked.map(t => {
                                return <div key={t} onClick={() => selectObject(t)} style={{ background: selectedObject === t ? 'yellow' : '' }}>
                                    {t}
                                </div>
                            })}
                            {tracked.length === 0 && <p>Brak śledzonych celów</p>}
                        </div>
                    }
                    <div>
                        <button onClick={onFire}>OGNIA!</button>
                    </div>
                </div>

            </Container>



        </FormProvider>

    </UIPanel>
}

export default UITorpedos;
