import { useMemo } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import useShields from "../../../modules/server/hooks/game/panels/use-shields";
import UIPanel from "../panel"

type Props = {}

const Container = styled.div`
    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 3fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 3fr 1fr 1fr 1fr 1fr;


    }

    .shield-level {
        label {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    .on {
        background: green;
    }

    .off {
        background: grey;
    }

    .full {
        background: blue;
    }

    .freeze {
        background: yellow;
    }

    .center {
        text-align: center;
        border: 1px solid black;
    }
`;

const UIShields: React.FC<Props> = () => {

    const { register, watch, setValue } = useForm();
    const {
        setShieldLevel,
        shieldsPoints,
        front1states, front2states, front3states, frontLevel,
        front1health, front1maxHealth,
        front2health, front2maxHealth,
        front3health, front3maxHealth,

        left1states, left2states, left3states, leftLevel,
        left1health, left1maxHealth,
        left2health, left2maxHealth,
        left3health, left3maxHealth,

        back1states, back2states, back3states, backLevel,
        back1health, back1maxHealth,
        back2health, back2maxHealth,
        back3health, back3maxHealth,

        right1states, right2states, right3states, rightLevel,
        right1health, right1maxHealth,
        right2health, right2maxHealth,
        right3health, right3maxHealth,
    } = useShields()

    const freeShieldPoints = useMemo(() => {
        return (shieldsPoints || 0) - (frontLevel || 0) - (backLevel || 0) - (rightLevel || 0) - (leftLevel || 0);
    }, [shieldsPoints, frontLevel, backLevel, rightLevel, leftLevel]);

    const front = watch('front');

    useEffect(() => {
        const v = parseInt(front);

        if (!isNaN(v)) {
            setShieldLevel('front', v);
        }

    }, [front, setShieldLevel])

    useEffect(() => {
        setValue('front', `${frontLevel}`);
    }, [frontLevel, setValue]);

    const left = watch('left');

    useEffect(() => {
        const v = parseInt(left);

        if (!isNaN(v)) {
            setShieldLevel('left', v);
        }

    }, [left, setShieldLevel])

    useEffect(() => {
        setValue('left', `${leftLevel}`);
    }, [leftLevel, setValue]);

    const back = watch('back');

    useEffect(() => {
        const v = parseInt(back);

        if (!isNaN(v)) {
            setShieldLevel('back', v);
        }

    }, [back, setShieldLevel])

    useEffect(() => {
        setValue('back', `${backLevel}`);
    }, [backLevel, setValue]);

    const right = watch('right');

    useEffect(() => {
        const v = parseInt(right);

        if (!isNaN(v)) {
            setShieldLevel('right', v);
        }

    }, [right, setShieldLevel])

    useEffect(() => {
        setValue('right', `${rightLevel}`);
    }, [rightLevel, setValue]);

    return <UIPanel title="Tarcze">
        <Container>
            <div>
                Wykożystanie tarczy: {freeShieldPoints}/{shieldsPoints}
            </div>

            <div className="grid">
                <div style={{ gridRow: 5, gridColumn: 5 }} className="center">Statek z dzobem w góre</div>

                <div style={{ gridRow: 4, gridColumn: 5 }} className={`shield-level front-0`}>
                    <label>
                        <input type="radio" value={0} {...register('front')} />
                    </label>
                </div>
                <div style={{ gridRow: 3, gridColumnStart: 4, gridColumnEnd: 7 }} className={`shield-level front-1 ${front1states.join(' ')}`}>
                    <label>
                        <input type="radio" value={1} disabled={freeShieldPoints + (frontLevel || 0) < 1} {...register('front')} />{(front1health || 0) | 0}/{front1maxHealth}
                    </label>
                </div>
                <div style={{ gridRow: 2, gridColumnStart: 3, gridColumnEnd: 8 }} className={`shield-level front-2 ${front2states.join(' ')}`}>
                    <label>
                        <input type="radio" value={2} disabled={freeShieldPoints + (frontLevel || 0) < 2} {...register('front')} />{(front2health || 0) | 0}/{front2maxHealth}
                    </label>
                </div>
                <div style={{ gridRow: 1, gridColumnStart: 2, gridColumnEnd: 9 }} className={`shield-level front-3 ${front3states.join(' ')}`}>
                    <label>
                        <input type="radio" value={3} disabled={freeShieldPoints + (frontLevel || 0) < 3} {...register('front')} />{(front3health || 0) | 0}/{front3maxHealth}
                    </label>
                </div>

                <div style={{ gridRow: 5, gridColumn: 4 }} className={`shield-level left-0`}>
                    <label>
                        <input type="radio" value={0} {...register('left')} />
                    </label>
                </div>
                <div style={{ gridRowStart: 4, gridRowEnd: 7, gridColumn: 3 }} className={`shield-level left-1 ${left1states.join(' ')}`}>
                    <label>
                        <input type="radio" value={1} disabled={freeShieldPoints + (leftLevel || 0) < 1} {...register('left')} />{(left1health || 0) | 0}/{left1maxHealth}
                    </label>
                </div>
                <div style={{ gridRowStart: 3, gridRowEnd: 8, gridColumn: 2 }} className={`shield-level left-2 ${left2states.join(' ')}`}>
                    <label>
                        <input type="radio" value={2} disabled={freeShieldPoints + (leftLevel || 0) < 2} {...register('left')} />{(left2health || 0) | 0}/{left2maxHealth}
                    </label>
                </div>
                <div style={{ gridRowStart: 2, gridRowEnd: 9, gridColumn: 1 }} className={`shield-level left-3 ${left3states.join(' ')}`}>
                    <label>
                        <input type="radio" value={3} disabled={freeShieldPoints + (leftLevel || 0) < 3} {...register('left')} />{(left3health || 0) | 0}/{left3maxHealth}
                    </label>
                </div>

                <div style={{ gridRow: 6, gridColumn: 5 }} className={`shield-level back-0`}>
                    <label>
                        <input type="radio" value={0} {...register('back')} />
                    </label>
                </div>
                <div style={{ gridRow: 7, gridColumnStart: 4, gridColumnEnd: 7 }} className={`shield-level back-1 ${back1states.join(' ')}`}>
                    <label>
                        <input type="radio" value={1} disabled={freeShieldPoints + (backLevel || 0) < 1} {...register('back')} />{(back1health || 0) | 0}/{back1maxHealth}
                    </label>
                </div>
                <div style={{ gridRow: 8, gridColumnStart: 3, gridColumnEnd: 8 }} className={`shield-level back-2 ${back2states.join(' ')}`}>
                    <label>
                        <input type="radio" value={2} disabled={freeShieldPoints + (backLevel || 0) < 2} {...register('back')} />{(back2health || 0) | 0}/{back2maxHealth}
                    </label>
                </div>
                <div style={{ gridRow: 9, gridColumnStart: 2, gridColumnEnd: 9 }} className={`shield-level back-3 ${back3states.join(' ')}`}>
                    <label>
                        <input type="radio" value={3} disabled={freeShieldPoints + (backLevel || 0) < 3} {...register('back')} />{(back3health || 0) | 0}/{back3maxHealth}
                    </label>
                </div>

                <div style={{ gridRow: 5, gridColumn: 6 }} className={`shield-level right-0`}>
                    <label>
                        <input type="radio" value={0} {...register('right')} />
                    </label>
                </div>
                <div style={{ gridRowStart: 4, gridRowEnd: 7, gridColumn: 7 }} className={`shield-level right-1 ${right1states.join(' ')}`}>
                    <label>
                        <input type="radio" value={1} disabled={freeShieldPoints + (rightLevel || 0) < 1} {...register('right')} />{(right1health || 0) | 0}/{right1maxHealth}
                    </label>
                </div>
                <div style={{ gridRowStart: 3, gridRowEnd: 8, gridColumn: 8 }} className={`shield-level right-2 ${right2states.join(' ')}`}>
                    <label>
                        <input type="radio" value={2} disabled={freeShieldPoints + (rightLevel || 0) < 2} {...register('right')} />{(right2health || 0) | 0}/{right2maxHealth}
                    </label>
                </div>
                <div style={{ gridRowStart: 2, gridRowEnd: 9, gridColumn: 9 }} className={`shield-level right-3 ${right3states.join(' ')}`}>
                    <label>
                        <input type="radio" value={3} disabled={freeShieldPoints + (rightLevel || 0) < 3} {...register('right')} />{(right3health || 0) | 0}/{right3maxHealth}
                    </label>
                </div>
            </div>
        </Container>
    </UIPanel>
}

export default UIShields;
