import { useCallback } from "react";
import styled from "styled-components";
import useTorpedos from "../../../modules/server/hooks/game/panels/use-torpedos";
import MapMasterComponent from "../../map-master-component";

type Props = {
    torpedoId: string
}

const Container = styled.div`

    .map-container {
        width: 100%;
        padding-top: 100%;
        position: relative;
    }

    .map-container-inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
`;

const UIContainer = styled.div`
    position: absolute;
    bottom: 5px;
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`;

const TorpedoControlls: React.FC<Props> = ({ torpedoId }) => {

    const { steerTorpedo } = useTorpedos();

    const onLeft = useCallback(() => {
        steerTorpedo(torpedoId, -1);
    }, [steerTorpedo, torpedoId]);

    const onRight = useCallback(() => {
        steerTorpedo(torpedoId, 1);
    }, [steerTorpedo, torpedoId]);

    return <Container>
        <div className="map-container">
            <div className="map-container-inner">
                <MapMasterComponent centerOn={torpedoId} options={{showMoveControlls: false, showZoomControlls: false}} />
            </div>
            <UIContainer>
                <button onClick={onLeft}>Lewo</button>
                <button onClick={onRight} style={{ gridColumn: 3 }}>Prawo</button>
            </UIContainer>
        </div>
    </Container>
}

export default TorpedoControlls;
