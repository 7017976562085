import React, { useContext } from "react";
import { useGame } from ".";
import { DirectorSchema } from "../../schemas/DirectorSchema";
import { LogSchema } from "../../schemas/LogSchema";
import useArraySchema from "./hooks/use-array-schema";

type GameData = {
    logs: LogSchema[],
    director?: DirectorSchema
}

export default function createGameAdminProvider() {
    const context = React.createContext<GameData>({
        logs: [],
    });

    const GameAdminProvider: React.FC<{ id: string }> = ({ children, id }) => {
        const { state } = useGame();

        const logs = useArraySchema<LogSchema>(state?.logs.logs);

        const data: GameData = {
            logs,
            director: state?.director
        };

        return (
            <context.Provider value={data}>
                {children}
            </context.Provider>
        )
    }

    const useGameAdmin = () => {
        return useContext(context);
    }

    return {
        GameAdminProvider,
        useGameAdmin
    }
}
