import Axios from 'axios';
import CONFIG from '../config';

/**
 * Axios instance to comunicate with local PHP backend
 */
const APIClient = Axios.create({
    // baseURL: '//localhost:8081',
    baseURL: CONFIG.SERVER_URL,
    withCredentials: true
})

type GetConfigResponse = {
    serverTime: number,
    user: any,
    gameAuthToken: string
}

function getConfig(): Promise<GetConfigResponse> {
    return APIClient.get('/config')
        .then((response) => {
            return {
                serverTime: Date.now(),
                user: response.data.user,
                gameAuthToken: response.data.gameAuthToken
            }
        });
}

function login(login: string) {
    return APIClient.post('/login', {login})
        .then((response) => {
            return {
                user: response.data.user
            }
        })
}

function logout() {
    return APIClient.post('/logout')
}


const Network = {
    getConfig,
    login,
    logout
}

export default Network;
