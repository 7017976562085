import { useEffect, useMemo, useState } from "react";
import { useGame } from "../..";

export default function useGameObject(id: string) {
    const { gameObjects } = useGame();

    const gameObject = useMemo(() => {
        return gameObjects.find(s => s.id === id);
    }, [gameObjects, id]);

    const [x, setX] = useState(0);
    const [y, setY] = useState(0);

    useEffect(() => {
        if (gameObject) {
            setX(gameObject.x);
            setY(gameObject.y);

            const delX = gameObject.listen('x', x => {
                setX(x);
            })

            const delY = gameObject.listen('y', y => {
                setY(y);
            })

            return () => {
                delX(); delY();
            }
        }
    }, [setX, setY, gameObject]);

    return {
        gameObject,
        x, y
    }
}
