import { useMemo } from "react";
import { useMyShip } from "../..";
import useGameObject from "./use-game-object";
export default function useDistance(id: string) {
    const { x, y } = useMyShip();
    const { x: x2, y: y2 } = useGameObject(id);


    return useMemo(() => {
        return Math.sqrt(Math.pow(x - x2, 2) + Math.pow(y - y2, 2));
    }, [x, y, x2, y2]);
}
