import { useGame } from "../../modules/server";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { Layout, Responsive, WidthProvider } from 'react-grid-layout'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import StationController from "./station-controller";
import getLayouts, { BASE_LAYOUTS } from "./get-layouts";
import PANELS from "./panels";
import useMyPanels from "../../modules/server/hooks/game/use-my-panels";

const ResponsiveGridLayout = WidthProvider(Responsive);

type Props = {}

const Container = styled.div`

`;

const COLS = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }
const BREAKPOINTS = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }

const Grid: React.FC<Props> = () => {

    const { shipId, damageState } = useGame();
    const [actualLayout, setLayout] = useState<Layout[]>([]);
    const [breakpoint, setBreakpoint] = useState('xxs');

    const myPanels = useMyPanels();

    const [layouts, setLayouts] = useState(getLayouts());

    const layoutsToSave = useMemo(() => {
        //@ts-ignore
        let old = layouts[breakpoint];

        actualLayout.forEach(al => {
            old = [
                ...old.filter((o: Layout) => o.i !== al.i),
                al
            ];
        });

        const result = {
            ...layouts
        };

        //@ts-ignore
        result[breakpoint] = old;

        return result;
    }, [actualLayout, layouts, breakpoint]);

    useEffect(() => {
        localStorage.setItem('game-layouts', JSON.stringify(layoutsToSave));
    }, [layoutsToSave]);

    const onLayoutChange = useCallback((l: any) => {
        setLayout(Array.isArray(l) ? l : []);
    }, [setLayout]);

    const onBreakpointChange = useCallback((v: any) => {
        setBreakpoint(v);
    }, [setBreakpoint]);

    const panelsContainer = useRef<HTMLDivElement>(null);

    const [visiblePanels, setVisiblePanels] = useState<string[]>(getVisiblePanels())

    useEffect(() => {
        localStorage.setItem('visible-panels', JSON.stringify(visiblePanels));
    }, [visiblePanels]);

    const panels = useMemo(() => {
        if (!shipId) {
            return []
        } else {
            return PANELS
                .filter(p => myPanels.includes(p.id))
                .filter(p => visiblePanels.includes(p.id))
        }
    }, [shipId, visiblePanels, myPanels]);

    const items = useMemo(() => {
        return panels.map(({ id, item }) => {

            //@ts-ignore
            const layout: Layout[] = layouts[breakpoint];

            const dataGrid = Array.isArray(layout) ? layout.find((l: any) => l.i === id) : null;

            if (dataGrid) {
                return <div key={id} data-grid={dataGrid}>{item}</div>
            } else {
                return <div key={id}>{item}</div>
            }

        });
    }, [breakpoint, panels, layouts]);

    useLayoutEffect(() => {
        if (panelsContainer.current) {
            const w = panelsContainer.current.getBoundingClientRect().width;


            const b = Object.keys(BREAKPOINTS).reduce((prev: string, key) => {

                //@ts-ignore
                if (w < BREAKPOINTS[key]) {
                    return prev;
                }

                //@ts-ignore
                if (BREAKPOINTS[key] > BREAKPOINTS[prev]) {
                    return key;
                }

                return prev;
            }, 'xxs')

            setBreakpoint(b);
        }
    }, [setBreakpoint])

    const resetLayout = useCallback(() => {
        setLayouts(BASE_LAYOUTS);
        setVisiblePanels(PANELS.map(p => p.id));
    }, [setLayouts]);

    const panelsForControls = useMemo(() => {
        return PANELS
            .map(P => {
                return {
                    ...P,
                    visible: visiblePanels.includes(P.id)
                }
            })
            .filter(p => myPanels.includes(p.id))
    }, [myPanels, visiblePanels])

    const layoutInfo = useMemo(() => {
        const myVisiblePanels = visiblePanels.filter(p => myPanels.includes(p));

        return {
            visible: myVisiblePanels,
            layouts: {
                lg: layoutsToSave.lg.filter(l => myVisiblePanels.includes(l.i)),
                md: layoutsToSave.md.filter(l => myVisiblePanels.includes(l.i)),
                sm: layoutsToSave.sm.filter(l => myVisiblePanels.includes(l.i)),
                xs: layoutsToSave.xs.filter(l => myVisiblePanels.includes(l.i)),
                xxs: layoutsToSave.xxs.filter(l => myVisiblePanels.includes(l.i))
            }
        }
    }, [visiblePanels, layoutsToSave, myPanels])

    return <Container>
        <div className="console-wrapper">
            <div className={`panels-container ${damageState}`} ref={panelsContainer}>


                <ResponsiveGridLayout
                    className="layout"
                    draggableHandle=".move-handle"
                    onLayoutChange={onLayoutChange}
                    onBreakpointChange={onBreakpointChange}
                    breakpoints={BREAKPOINTS}
                    layouts={layouts}
                    cols={COLS}>
                    {items}
                </ResponsiveGridLayout>

            </div>
        </div>
        <div className="layout-controller">
            <h3>Konfiguracja stanowiska</h3>
            <StationController items={panelsForControls} onChange={setVisiblePanels} layoutInfo={layoutInfo} />
            <button onClick={resetLayout}>Reset</button>
        </div>
    </Container>
}

export default Grid;





export function getVisiblePanels() {
    try {
        const stored = JSON.parse(localStorage.getItem('visible-panels') || '');

        return stored || PANELS.map(p => p.id);

    } catch (e) {
        return PANELS.map(p => p.id);
    }
}
