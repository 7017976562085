import { useRef, useCallback } from "react";
import styled from "styled-components";
import { useGameLobby } from "../../modules/server";

type Props = {}

const Container = styled.div`
    width: 100%;
    h2 {
        padding-bottom: .5em;
        border-bottom: 1px solid black;
        width: 100%;
        text-align: center;
    }

    .row {
        width: 100%;
        margin: .5em 0;

        .inner {
            padding: .5em;
            display: flex;
            flex-direction: row;

            justify-content: space-between;

        }

        select {
            min-width: 50%;
        }

    }
`;

const ScenarioSelectForm: React.FC<Props> = () => {

    const select = useRef<HTMLSelectElement>(null);
    const { scenarioList, selectScenario } = useGameLobby();

    const onSelect = useCallback(() => {
        if (select.current) {
            selectScenario(select.current.value);
        }
    }, [selectScenario]);

    return <Container>
        <h2>Wybierz scenariusz</h2>
        <div className="row">
            <div className="inner">
                <select name="" id="" ref={select}>
                    {
                        scenarioList && scenarioList.map(s => {
                            return <option value={s.id} key={s.id}>{s.name}</option>
                        })
                    }
                </select>
                <button onClick={onSelect}>Wybierz</button>
            </div>
        </div>
    </Container>
}

export default ScenarioSelectForm;
