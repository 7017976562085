import MapMasterComponent from "../../map-master-component";
import UIPanel from "../panel"

type Props = {}

const UIEnergyRadar: React.FC<Props> = () => {
    return <UIPanel title={'Radar energii'}>
        <MapMasterComponent options={{
            showEnergy: true,
            showRadar: false
        }} />
    </UIPanel>
}

export default UIEnergyRadar;
