import styled from "styled-components";
import pauseSrc from '../../../assets/images/gui/controlls/pause.png';

import leftSrc from '../../../assets/images/gui/controlls/left.png';
import upSrc from '../../../assets/images/gui/controlls/up.png';
import rightSrc from '../../../assets/images/gui/controlls/right.png';
import downSrc from '../../../assets/images/gui/controlls/down.png';

import leftHoverSrc from '../../../assets/images/gui/controlls/left-hover.png';
import upHoverSrc from '../../../assets/images/gui/controlls/up-hover.png';
import rightHoverSrc from '../../../assets/images/gui/controlls/right-hover.png';
import downHoverSrc from '../../../assets/images/gui/controlls/down-hover.png';

type Props = {};

const Container = styled.div`
    padding-top: 100%;
    position: relative;

    .button-left {
        position: absolute;
        left: 0;
        top: 23%;
        height: 52%;
        width: 25%;

        background: url(${leftSrc}) 50% 50% no-repeat;
        background-size: contain;
        border: none;
        cursor: pointer;

        &:hover {
            background-image: url(${leftHoverSrc});
        }
    }

    .button-up {
        position: absolute;
        left: 23%;
        top: 0;
        height: 25%;
        width: 52%;

        background: url(${upSrc}) 50% 50% no-repeat;
        background-size: contain;
        border: none;
        cursor: pointer;

        &:hover {
            background-image: url(${upHoverSrc});
        }
    }

    .button-right {
        position: absolute;
        left: 75%;
        top: 23%;
        height: 52%;
        width: 25%;

        background: url(${rightSrc}) 50% 50% no-repeat;
        background-size: contain;
        border: none;
        cursor: pointer;

        &:hover {
            background-image: url(${rightHoverSrc});
        }
    }

    .button-down {
        position: absolute;
        left: 23%;
        top: 75%;
        height: 25%;
        width: 52%;

        background: url(${downSrc}) 50% 50% no-repeat;
        background-size: contain;
        border: none;
        cursor: pointer;

        &:hover {
            background-image: url(${downHoverSrc});
        }
    }

    .button-pause {
        position: absolute;
        top: 19%;
        left: 19%;

        width: 62%;
        height: 62%;

        border-radius: 50%;

        background: url(${pauseSrc}) 50% 50% no-repeat;
        background-size: contain;
        border: none;
        cursor: pointer;
    }
`;

const SteerButtons: React.FC<Props> = () => {
    return <Container>
        <button className="button-left"></button>
        <button className="button-up"></button>
        <button className="button-right"></button>
        <button className="button-down"></button>
        <button className="button-pause"></button>
    </Container>
}

export default SteerButtons;
