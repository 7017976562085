import { Room } from "colyseus.js";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useContext } from "react";
import { useHistory } from "react-router";
import { useServer } from ".";
import { getGameRoute, getServerLobbyRoute } from "../../routes/routes";
import { LobbyScenarioMetaSchema } from "../../schemas/LobbyScenarioMetaSchema";
import { LobbyScenarioSchema } from "../../schemas/LobbyScenarioSchema";
import { ArraySchema } from '@colyseus/schema';
import { GameLobbySchema } from "../../schemas/GameLobbySchema";
import useMapSchema from "./hooks/use-map-schema";
import { LobbyUserSchema } from "../../schemas/LobbyUserSchema";
import useBasicSchema from "./hooks/use-basic-schema";
import { RulesSchema } from "../../schemas/RulesSchema";

type GameLobbyData = {
    id: string,
    state: GameLobbySchema | null,
    isScenarioSelected: boolean,

    scenario?: LobbyScenarioSchema,
    scenarioList?: ArraySchema<LobbyScenarioMetaSchema>,
    users: LobbyUserSchema[],

    isGameInProgress: boolean,
    gameRoomId?: string,

    room?: Room<RulesSchema>,
    rules?: RulesSchema | null,

    leave(): void,

    selectScenario(id: string): void,

    leaveSlot(): void,
    takeSlot(shipId: string, slotId: string): void,

    startGame(): void
}

export default function createGameLobbyProvider() {

    const context = React.createContext<GameLobbyData>({
        id: '',
        state: null,
        isScenarioSelected: false,
        users: [],

        isGameInProgress: false,
        gameRoomId: '',

        leave() { },
        selectScenario() { },

        leaveSlot() { },
        takeSlot() { },

        startGame() { }
    });

    const GameLobbyProvider: React.FC<{ id: string }> = ({ children, id }) => {

        const { gameServer } = useServer();
        const history = useHistory();

        const [room, setRoom] = useState<Room>();
        const [scenarioList, setScenarioList] = useState<ArraySchema<LobbyScenarioMetaSchema>>();
        const [scenario, setScenario] = useState<LobbyScenarioSchema>();


        const state: GameLobbySchema | null = useMemo(() => {
            if (room) {
                return room.state;
            }

            return null;
        }, [room]);

        const isGameInProgress = useBasicSchema<GameLobbySchema, boolean>(state, 'isGameInProgress');
        const gameRoomId = useBasicSchema<GameLobbySchema, string>(state, 'gameRoomId');

        const users = useMapSchema(state?.users);

        useEffect(() => {
            gameServer.joinById(id)
                .then(room => {

                    room.onMessage('game-started', ({ roomId }) => {
                        history.push(getGameRoute(roomId));
                    });

                    setRoom(room);
                })
                .catch(() => {
                    history.push(getServerLobbyRoute());
                });

            return () => { }
        }, [gameServer, id, history]);

        useEffect(() => {
            if (state) {
                state.listen('scenarioList', (list) => {
                    setScenarioList(list);
                })

                state.listen('scenario', scenario => {
                    setScenario(scenario);
                });
            }
        }, [state, setScenarioList]);

        const selectScenario = useCallback((id: string) => {
            if (room) {
                room.send('select-scenario', {
                    id
                });
            }
        }, [room])

        const leave = useCallback(() => {
            if (room) {
                room.leave(true);
            }
        }, [room]);

        const takeSlot = useCallback((shipId: string, slotId: string) => {
            if (room) {
                room.send('take-slot', {
                    shipId,
                    slotId
                });
            }
        }, [room])

        const leaveSlot = useCallback(() => {
            if (room) {
                room.send('leave-slot');
            }
        }, [room]);

        const startGame = useCallback(() => {
            if (room) {
                room.send('start-game', {});
            }
        }, [room]);

        const data: GameLobbyData = {
            id,
            state,
            isScenarioSelected: Boolean(scenario),

            scenario,
            scenarioList,
            users,

            isGameInProgress: isGameInProgress || false,
            gameRoomId,

            room,
            rules: state?.rules,

            leave,
            selectScenario,

            leaveSlot,
            takeSlot,

            startGame
        }

        return (
            <context.Provider value={data}>
                {children}
            </context.Provider>
        )
    }

    const useGameLobby = () => {
        return useContext(context);
    }


    return {
        GameLobbyProvider,
        useGameLobby
    }

}
