import { useCallback, useMemo } from "react";
import { useGame, useMyShip } from "../../..";
import { TrackingSchema } from "../../../../../schemas/TrackingSchema";
import useBasicSchema from "../../use-basic-schema";
import useObjectInfo from "./use-object-info";

export default function useScanner() {
    const { ship } = useMyShip();
    const { room } = useGame();

    const scanCompletion = useBasicSchema<TrackingSchema, number>(ship?.tracking, 'scanCompletion');
    const scanningObject = useBasicSchema<TrackingSchema, string>(ship?.tracking, 'scanningObject');

    const { object: selectedObject } = useObjectInfo();

    const scanInProgress = useMemo(() => {
        return Boolean(scanningObject)
    }, [scanningObject]);

    const objectId = useMemo(() => {
        if (scanInProgress) {
            return scanningObject;
        } else {
            return selectedObject?.id
        }
    }, [scanningObject, selectedObject, scanInProgress]);

    const isKnown = useMemo(() => {
        return selectedObject?.known;
    }, [selectedObject]);

    const startScan = useCallback(() => {
        if (objectId && room) {
            room.send('start-scan', {
                targetId: objectId
            })
        }
    }, [room, objectId]);


    return {
        objectId,
        startScan,
        scanCompletion,
        scanInProgress,
        isKnown
    }
}
