import { useCallback, useMemo } from "react";
import { useGame, useMyShip } from "../../..";
import useMapSchema from "../../use-map-schema";

export default function useTorpedos() {

    const { ship } = useMyShip();
    const { room, gameObjects, torpedos } = useGame();

    const shipTorpedos = useMapSchema(ship?.torpedos.storage);

    const fireTorpedo = useCallback((type: string, course: number) => {
        if (room) {
            room.send('fire-torpedo', {
                type,
                angle: course * (Math.PI / 180)
            });
        }
    }, [room]);

    const fireOnTarget = useCallback((type: string, id: string) => {
        const target = gameObjects.find(go => go.id === id);
        const shipGO = gameObjects.find(go => go.id === ship?.id);

        if (target && shipGO) {
            let angle = Math.atan2(target.x - shipGO.x, shipGO.y - target.y)

            if (angle < 0) {
                angle += Math.PI * 2;
            }

            if (room) {
                room.send('fire-torpedo', {
                    type,
                    angle
                });
            }
        }

    }, [room, gameObjects, ship]);

    const myActiveTorpedos = useMemo(() => {
        return torpedos.filter(t => t.source.id === ship?.id);
    }, [torpedos, ship]);

    const steerTorpedo = useCallback((torpedoId: string, value: number) => {
        if (room) {
            room.send('steer-torpedo', {
                torpedoId,
                value
            });
        }
    }, [room]);

    return {
        shipTorpedos,
        fireTorpedo,
        fireOnTarget,
        myActiveTorpedos,
        steerTorpedo
    }
}
