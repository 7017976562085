import styled from "styled-components"
import useLobby from "../../modules/server/hooks/server-lobby/use-lobby";
import InProgressGame from "./in-progress-game";

type Props = {}

const Container = styled.div`
    h2 {
        padding-bottom: .5em;
        border-bottom: 1px solid black;
        width: 100%;
        text-align: center;
    }

    .row {
        width: 100%;
        margin: 0.5em;
    }

    .list {
        padding: 1em;
        background: #d9d9d9;
    }

    .empty {
        color: #5e5e5e;
        margin: 1em;
        border: 1px dashed #5e5e5e;
        padding: 1em;
        text-align: center;
    }
`;

const InProgressList: React.FC<Props> = () => {

    const { inProgressRooms } = useLobby();

    return <Container className="box">
        <h2>Lista gier w trakcie</h2>
        <div className="row">
            <div className="list">
                {
                    inProgressRooms.map((room) => {
                        return <InProgressGame key={room.roomId} room={room} />
                    })
                }
                {!inProgressRooms.length && <div className="empty">Brak gier</div>}
            </div>
        </div>
    </Container>
}

export default InProgressList
