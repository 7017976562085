import * as PIXI from 'pixi.js';

export default class MapObject extends PIXI.Container {

    private gfx = new PIXI.Graphics();
    private text = new PIXI.Text('?', { fill: 0x3ec4fb });

    private _isTracked = false;
    private _isMe = false;

    public _type: string = '';

    set type(v: string) {
        this._type = v;
        this.update();
    }

    public selected = false;

    setSelected(v: boolean) {
        if (this.selected !== v) {
            this.selected = v;
            this.update();
        }
    }

    getColor() {
        return this.selected ? 0xffff00 : 0x3ec4fb;
    }

    set isTracked(value: boolean) {
        if (this._isTracked !== value) {
            this._isTracked = value;
            this.update();
        }
    }

    set isMe(value: boolean) {
        if (this._isMe !== value) {
            this._isMe = value;
            this.update();
        }
    }


    constructor() {
        super();


        this.text.visible = false;
        this.text.anchor.set(.5);

        this.addChild(this.gfx);
        this.addChild(this.text);

        this.interactive = true;
        this.cursor = 'pointer';

        this.hitArea = new PIXI.Rectangle(-10, -10, 20, 20);

        this.update();
    }

    update() {
        this.gfx.clear();
        this.text.style.fill = this.getColor();

        if (this._type === 'ship') {
            if (this._isMe || this._isTracked) {
                this.text.visible = false;

                this.gfx.lineStyle(2, this.getColor(), 1);
                this.gfx.drawCircle(0, 0, 5);
                this.gfx.moveTo(0, 0);
                this.gfx.lineTo(0, 10);
                this.gfx.moveTo(-3, 10);
                this.gfx.lineTo(3, 10);
            } else {
                this.text.visible = true;
            }
        }

        if (this._type === 'torpedo') {
            this.text.visible = false;
            this.gfx.lineStyle(2, this.getColor(), 1);
            this.gfx.moveTo(-3, -3);
            this.gfx.lineTo(3, 3);
            this.gfx.moveTo(3, -3);
            this.gfx.lineTo(-3, 3);
        }

        if (this._type === 'decoy') {
            this.text.scale.set(1);
            this.text.visible = false;

            this.gfx.lineStyle(1, this.getColor(), 1);

            [
                [0 / 8, 1 / 8], [2 / 8, 3 / 8], [4 / 8, 5 / 8], [6 / 8, 7 / 8]
            ].forEach(([s, e]) => {
                this.gfx.arc(0, 0, 10, s * Math.PI * 2, e * Math.PI * 2)
                this.gfx.closePath();
            })
        }

        if (this._type === 'container') {
            if (this._isTracked) {
                this.text.visible = false;

                this.gfx.lineStyle(1, this.getColor(), 1);
                this.gfx.drawRect(-2, -2, 4, 4);
            } else {
                this.text.scale.set(.5);
                this.text.visible = true;
            }
        }

        if (this._type === 'asteroid') {
            if (this._isTracked) {
                this.text.visible = false;

                this.gfx.lineStyle(2, this.getColor(), 1);
                this.gfx.moveTo(-5, 0);
                this.gfx.lineTo(0, 5);
                this.gfx.lineTo(5, 0);
                this.gfx.lineTo(0, -5);
                this.gfx.lineTo(-5, 0);
            } else {
                this.text.visible = true;
            }
        }

        if (this._type === 'space-station') {
            this.text.visible = false;

            this.gfx.lineStyle(2, this.getColor(), 1);
            this.gfx.moveTo(0, -10);
            this.gfx.lineTo(3, 0);
            this.gfx.lineTo(10, 0);
            this.gfx.lineTo(8, 2);
            this.gfx.lineTo(2, 2);
            this.gfx.lineTo(2, 4);
            this.gfx.lineTo(6, 4);
            this.gfx.lineTo(0, 5);
            this.gfx.lineTo(-6, 4);
            this.gfx.lineTo(-2, 4);
            this.gfx.lineTo(-2, 2);
            this.gfx.lineTo(-8, 2);
            this.gfx.lineTo(-10, 0);
            this.gfx.lineTo(-10, 0);
            this.gfx.lineTo(-3, 0);
            this.gfx.lineTo(-0, -10);
        }

    }
}
