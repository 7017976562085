import useScanner from "../../../modules/server/hooks/game/panels/use-scanner";
import UIPanel from "../panel"

type Props = {}

const UIScanner: React.FC<Props> = () => {

    const { scanInProgress, isKnown, objectId, startScan, scanCompletion } = useScanner();

    return <UIPanel title="Skaner">
        {!scanInProgress && <div>
            {isKnown && <div>
                Object id: {objectId}
            </div>}

            {!isKnown && <div>
                Obiekt nie znany <button onClick={startScan}>Zacznij skanowanie</button>
            </div>}
        </div>}

        {scanInProgress && <div>
            Postęp skanowania: {(scanCompletion || 0)*100 | 0}%
        </div>}
    </UIPanel>
}

export default UIScanner;
