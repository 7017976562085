import { useCallback } from "react";
import { useGame, useMyShip } from "../../..";
import { ShipReactorSchema } from "../../../../../schemas/ShipReactorSchema";
import { ShipStatusSchema } from "../../../../../schemas/ShipStatusSchema";
import useBasicSchema from "../../use-basic-schema";

export default function useReactor() {
    const { ship } = useMyShip();
    const { room } = useGame();

    const power = useBasicSchema<ShipReactorSchema, number>(ship?.reactor, 'power');
    const freePower = useBasicSchema<ShipReactorSchema, number>(ship?.reactor, 'freePower');

    const mainEngine = useBasicSchema<ShipReactorSchema, number>(ship?.reactor, 'mainEngine');
    const radar = useBasicSchema<ShipReactorSchema, number>(ship?.reactor, 'radar');
    const energyRadar = useBasicSchema<ShipReactorSchema, number>(ship?.reactor, 'energyRadar');
    const microphone = useBasicSchema<ShipReactorSchema, number>(ship?.reactor, 'microphone');
    const storage = useBasicSchema<ShipReactorSchema, number>(ship?.reactor, 'storage');
    const torpedos = useBasicSchema<ShipReactorSchema, number>(ship?.reactor, 'torpedos');
    const shields = useBasicSchema<ShipReactorSchema, number>(ship?.reactor, 'shields');
    const tracking = useBasicSchema<ShipReactorSchema, number>(ship?.reactor, 'tracking');
    const scaner = useBasicSchema<ShipReactorSchema, number>(ship?.reactor, 'scaner');

    const mainEngineHealth = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'mainEngine');
    const radarHealth = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'radar');
    const energyRadarHealth = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'energyRadar');
    const microphoneHealth = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'microphone');
    const storageHealth = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'storage');
    const torpedosHealth = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'torpedos');
    const shieldsHealth = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'shields');
    const trackingHealth = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'tracking');
    const scanerHealth = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'scaner');

    const setReactor = useCallback((system: String, value: number) => {
        console.log('set-reactor', system, value);
        if (room) {
            room.send('set-reactor', {
                system,
                value
            });
        }
    }, [room]);


    return {
        power,
        freePower,

        mainEngine, radar, energyRadar, microphone, storage, torpedos, shields, tracking, scaner,
        mainEngineHealth, radarHealth, energyRadarHealth, microphoneHealth, storageHealth, torpedosHealth, shieldsHealth, trackingHealth, scanerHealth,

        setReactor
    }
}
