import { RoomAvailable } from "colyseus.js";
import { useCallback } from "react";
import styled from "styled-components";

type Props = {
    room: RoomAvailable,
    joinHandler(id: string): void
}

const Container = styled.div`
    .inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 90%;
    }
`;

const OpenGame: React.FC<Props> = ({ room, joinHandler }) => {
    const onJoin = useCallback(() => {
        console.log('onJoin', room.roomId);
        joinHandler(room.roomId);
    }, [room, joinHandler])

    return <Container className="box">
        <div className="inner">
            <h3>Gra: {room.roomId}</h3>
            <button onClick={onJoin}>Wejdź</button>
        </div>

    </Container>
}

export default OpenGame;
