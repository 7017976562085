import { BehaviorSubject, first, Subject } from "rxjs";
import { GameObjectSchema } from "../../../schemas/GameObjectSchema";

export default class Follower {

    private $stopFollow = new Subject<any>();

    public startFollow(target: GameObjectSchema) {
        this.$stopFollow.next(null);

        this.followingId = target.id;

        const removeXListener = target.listen('x', x => {
            this.$lookAt.next([x, this.$lookAt.value[1]]);
        });

        const removeYListener = target.listen('y', y => {
            this.$lookAt.next([this.$lookAt.value[0], y]);
        });

        this.$lookAt.next([target.x, target.y]);

        this.$stopFollow.pipe(
            first()
        ).subscribe(() => {
            removeXListener();
            removeYListener();
        })
    }

    public followingId = '';

    public stopFollow() {
        this.$stopFollow.next(null);
        this.followingId = '';
    }

    $lookAt = new BehaviorSubject<[number, number]>([0, 0]);

}
