import React, { useContext, useState } from "react";

type LocalData = {
    selectedObject?: string,
    selectObject(value: string): void,
    adminMode: boolean
    setAdminMode(value: boolean): void
    mapCenterOn?: string,
    centerMap(value: string): void
}

export default function createLocalStateProvider() {
    const context = React.createContext<LocalData>({
        selectObject: (v: string) => {},
        adminMode: false,
        setAdminMode: (v: boolean) => {},
        centerMap: (v: string) => {},
    });

    const LocalStateProvider: React.FC<{}> = ({ children }) => {

        const [selectedObject, selectObject] = useState<string>();
        const [mapCenterOn, centerMap] = useState<string>();
        const [adminMode, setAdminMode] = useState<boolean>(false);

        const data: LocalData = {
            selectedObject, selectObject,
            mapCenterOn, centerMap,
            adminMode, setAdminMode
        }

        return (
            <context.Provider value={data}>
                {children}
            </context.Provider>
        )
    }

    const useLocalState = () => {
        return useContext(context);
    }

    return {
        LocalStateProvider,
        useLocalState
    }
}
