import styled from "styled-components";
import useBasicSchema from "../../../modules/server/hooks/use-basic-schema";
import { TorpedoOnShipSchema } from "../../../schemas/TorpedoOnShipSchema";
import { useFormContext } from 'react-hook-form';
import { useCallback } from "react";
import { useGame } from "../../../modules/server";
import { useMemo } from "react";

type Props = {
    torpedos: TorpedoOnShipSchema[]
}

const Container = styled.div`
    td {
        text-align: center;
    }
`;

const TopedosStorage: React.FC<Props> = ({ torpedos }) => {
    return <Container>
        <table>
            <tbody>
                <tr>
                    <td></td>
                    <td>Typ</td>
                    <td>Ilość</td>
                    <td>Prędkość</td>
                    <td>Obrażenia</td>
                    <td>Paliwo</td>
                    <td>Zwrotność</td>
                </tr>
                {
                    torpedos.map((t, index) => {
                        return <Row key={index} checked={index===0} torpedo={t} />
                    })
                }
            </tbody>

        </table>
    </Container>
}

const RowContainer = styled.tr`
    cursor: pointer;
    :hover {
        background: yellow;
    }
`;

const Row: React.FC<{ torpedo: TorpedoOnShipSchema, checked?: boolean }> = ({ torpedo, checked }) => {

    const type = useBasicSchema<TorpedoOnShipSchema, string>(torpedo, 'type');
    const count = useBasicSchema<TorpedoOnShipSchema, number>(torpedo, 'count');
    const { register, setValue } = useFormContext();
    const {torpedoTypes} = useGame()

    const onClick = useCallback(() => {
        setValue('torpedoType', type)
    }, [setValue, type]);

    const torpedoData = useMemo(() => {
        const data = torpedoTypes.find(tt => tt.type === torpedo.type);

        if (data) {
            return {
                speed: `${data.velosity}`,
                time: `${data.time}`,
                damage: `${data.damage}`,
                turning: `${data.turningSpeed}`
            }
        } else {
            return {
                speed: `?`,
                time: `?`,
                damage: `?`,
                turning: `?`
            }
        }
    }, [torpedo, torpedoTypes])

    return <RowContainer onClick={onClick} style={{}}>
        <td>
            <input type="radio" value={type} {...register('torpedoType')} />
        </td>
        <td>{type}</td>
        <td>{(count || 0) > 999999 ? '\u221E' : count}</td>
        <td>{torpedoData.speed}</td>
        <td>{torpedoData.damage}</td>
        <td>{torpedoData.time}</td>
        <td>{torpedoData.turning}</td>
    </RowContainer>
}

export default TopedosStorage
