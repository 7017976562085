import { useMutation, useQueryClient } from "react-query";
import Network from "../../network";


export default function useLogout() {

    const queryClient = useQueryClient();

    const {mutate, isLoading, error} = useMutation(Network.logout, {
        onSuccess: () => {
            queryClient.invalidateQueries('get-config');
        }
    });

    return {
        logout: mutate,
        isLoading,
        error
    }
}
