import { useMemo } from "react";
import { useGame } from "../..";
import { GameUserSchema } from "../../../../schemas/GameUserSchema";
import { useMainModuleState } from "../../../main";
import useBasicSchema from "../use-basic-schema";

export default function useMySlot() {
    const { users, playerSlots } = useGame();
    const { user } = useMainModuleState();

    const me = useMemo(() => {
        return users.find(u => u.id === user.id);
    }, [users, user]);

    const mySlotId = useBasicSchema<GameUserSchema, string>(me, 'slotId');

    return useMemo(() => {
        return playerSlots.find(ps => ps.id === mySlotId);
    }, [playerSlots, mySlotId]);
}
