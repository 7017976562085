import UIPanel from "../panel"
import MicComponent from "./widget";

type Props = {}

const UIMic: React.FC<Props> = () => {

    return <UIPanel title="Mikrofon">
        <MicComponent />
    </UIPanel>
}

export default UIMic;
