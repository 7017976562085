import useTrakedList from "../../../modules/server/hooks/game/panels/use-tracking-list";
import UIPanel from "../panel"

type Props = {}

const UITrackingList: React.FC<Props> = () => {

    const { tracked, cancelTracking, selectObject, selectedObject } = useTrakedList();

    return <UIPanel title="Sledzone obiekty">
        <ol>
            {tracked.map((t) => {
                return <li key={t} style={{background: t === selectedObject ? 'yellow' : 'none'}} onClick={() => {selectObject(t)}}>{t}<button onClick={() => { cancelTracking(t) }}>anuluj</button></li>
            })}
        </ol>
    </UIPanel>
}

export default UITrackingList;
