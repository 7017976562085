import * as PIXI from 'pixi.js';

//@ts-ignore
import { ObjectPoolFactory } from '@pixi-essentials/object-pool';
import { GameObjectSchema } from '../../../../schemas/GameObjectSchema';
import MapObject from './object';
import { UpdateData } from '..';
// import { PixelateFilter } from '@pixi/filter-pixelate';
import { PixelateFilter } from '../../../../utils/pixi-filters/pixelate';
import { AreaSchema } from '../../../../schemas/AreaSchema';
import * as SAT from 'sat';
import RaycastResult from '../../../../utils/raycaster/result';
import { rayVSCircle } from '../../../../utils/raycaster';
import { isShip, isTorpedo } from '../../../../modules/server/game-provider';

const COLOR = 0x3ec4fb

export default class EnergyLayer extends PIXI.Container {
    private gfx = new PIXI.Graphics();
    private radar = new PIXI.Graphics();

    private asteroids = PIXI.Texture.from('asteroids');

    private shipPool = ObjectPoolFactory.build(MapObject);

    private objects = new Map<string, MapObject>();

    private areas: {
        area: AreaSchema,
        shape: SAT.Circle
    }[] = [];
    private range = 400;
    private range2 = this.range * this.range;

    constructor() {
        super();

        const pixelate = new PixelateFilter()

        pixelate.size = 5;

        this.filters = [pixelate];

        this.shipPool.reserve(100);
        // this.addChild(this.visMask);

        this.addChild(this.gfx);
        this.addChild(this.radar);
    }

    private checkSource(shipId: string, go: GameObjectSchema) {
        if (go.id === shipId && isShip(go)) {
            this.addSource(go.id, 500);
            return true;
        }

        if (isTorpedo(go) && go.source.id === shipId) {
            this.addSource(go.id, 250);
            return true;
        }

        return false;
    }

    update(data: UpdateData) {

        const existingObjects = new Set(this.objects.keys());
        const existingSources = new Set(this.sources.keys());

        if (!this.areasInitalized) {
            this.initAreas(data.areas.filter(a => a.energy > 0));
        }


        data.gameObjects.forEach(d => {
            const object = this.objects.get(d.id);
            const source = this.sources.get(d.id);


            if (source) {
                existingSources.delete(d.id);
            }

            if (object) {
                existingObjects.delete(d.id);

                object.isTracked = Boolean(data.tracked.find(t => t === d.id));
                object.isMe = Boolean(data.shipId === d.id);
            } else {
                const isSource = this.checkSource(data.shipId || '', d);
                const newSource = isSource ? this.sources.get(d.id) : null;
                if (newSource) {
                    newSource.center.x = d.x;
                    newSource.center.y = d.y;
                }

                if (d.energy) {
                    const newObject = this.add(d);

                    newObject.isTracked = Boolean(data.tracked.find(t => t === d.id));
                    newObject.isMe = Boolean(data.shipId === d.id);

                    newObject.setXY(d.x, d.y);


                    d.listen('x', (x => {
                        newObject.setX(x);
                        this.updateVisibility();
                    }))

                    d.listen('y', (y => {
                        newObject.setY(y);
                        this.updateVisibility();
                    }))

                    d.listen('angle', (angle => {
                        newObject.rotation = angle
                    }));
                }

                if (isSource) {
                    d.listen('x', (x => {
                        if (newSource) {
                            newSource.center.x = x;
                            this.updateVisibility();
                        }
                    }))

                    d.listen('y', (y => {
                        if (newSource) {
                            newSource.center.y = y;
                            this.updateVisibility();
                        }
                    }))
                }
            }

        })

        existingObjects.forEach(id => {
            this.remove(id);
        })

        existingSources.forEach(id => {
            this.remove(id);
        })
    }

    private areasInitalized = false;

    initAreas(areas: AreaSchema[]) {

        /* DEV */
        // areas.forEach(area => {
        //     switch (area.shape.type) {
        //         case 'circle':
        //             console.log('draw Circle', area.x, area.y, area.shape.radius);
        //             this.gfx.lineStyle(0);
        //             // this.gfx.beginFill(0xeeeeee, 1);
        //             this.gfx.beginTextureFill({
        //                 texture: this.asteroids
        //             })
        //             this.gfx.drawCircle(area.x, area.y, area.shape.radius);
        //             this.gfx.endFill();
        //             break;
        //     }
        // });

        this.areas = areas
            .map((area) => {
                switch (area.shape.type) {
                    case 'circle':
                        return {
                            area,
                            shape: new SAT.Circle(new SAT.Vector(area.x, area.y), area.shape.radius)
                        }

                    default:
                        return {
                            area,
                            shape: new SAT.Circle()
                        }
                }
            });

        this.updateVisibility();
    }
    private visUpdate = false;

    private sources: Map<string, {
        id: string,
        center: SAT.Vector,
        range: number
    }> = new Map();

    private addSource(id: string, range: number): SAT.Vector {
        const center = new SAT.Vector();
        this.sources.set(id, {
            id, center, range
        });

        return center;
    }

    updateVisibility() {
        if (!this.visUpdate) {
            this.visUpdate = true;
            requestAnimationFrame(() => {
                this.visUpdate = false;

                this.radar.clear();

                this.sources.forEach(({ center, range }) => {
                    const range2 = range * range;

                    const rangeMask = new SAT.Circle(center, range);
                    const areasInRange = this.areas.filter((a) => {
                        switch (a.area.shape.type) {
                            case 'circle':
                                return SAT.testCircleCircle(rangeMask, a.shape);
                        }

                        return false;
                    });

                    const insideArea = Boolean(areasInRange.find(a => {
                        switch (a.area.shape.type) {
                            case 'circle':
                                return SAT.pointInCircle(center, a.shape);
                        }

                        return false;
                    }));

                    if (!insideArea) {
                        this.radar.beginFill(COLOR, .7);

                        const visPoints: number[] = [];

                        const raysCount = 72 * 2;

                        for (let i = 0; i < raysCount; i++) {
                            const angle = i * Math.PI * 2 / raysCount;

                            const points: SAT.Vector[] = [];

                            const ray = new SAT.Vector(0, -1).rotate(angle).normalize();

                            const rayResult = new RaycastResult();

                            areasInRange.forEach((area) => {
                                switch (area.area.shape.type) {
                                    case 'circle':
                                        rayResult.reset();
                                        if (rayVSCircle(center, ray, area.shape.pos, area.shape.r, rayResult)) {
                                            points.push(rayResult.points[0]);
                                        }
                                }
                            });

                            const nearest = points.reduce((prev: SAT.Vector | null, point: SAT.Vector) => {
                                if (!prev) return point;

                                if (point.clone().sub(center).len2() < prev.clone().sub(center).len2()) {
                                    return point;
                                }

                                return prev;
                            }, null);

                            const dist = nearest ? nearest.clone().sub(center).len() : range;


                            // this.radar.drawPolygon([
                            //     center.x + dist * Math.sin(angle + step), center.y + dist * -Math.cos(angle + step),
                            //     center.x + (dist+10) * Math.sin(angle + step), center.y + (dist+10) * -Math.cos(angle + step),
                            //     center.x + (dist+10) * Math.sin(angle - step), center.y + (dist+10) * -Math.cos(angle - step),
                            //     center.x + dist * Math.sin(angle - step), center.y + dist * -Math.cos(angle - step),
                            // ])

                            visPoints.push(center.x + dist * Math.sin(angle), center.y + dist * -Math.cos(angle))

                            // if (nearest) {
                            //     this.radar.moveTo(center.x, center.y);
                            //     this.radar.lineTo(nearest.x, nearest.y);
                            //     this.radar.drawCircle(nearest.x, nearest.y, 5);
                            // } else {
                            //     this.radar.moveTo(center.x, center.y);
                            //     this.radar.lineTo(center.x + range * Math.sin(angle), center.y + range * -Math.cos(angle));
                            // }
                        }

                        this.radar.endFill();

                        this.radar.beginFill(COLOR, .2);
                        this.radar.drawPolygon(visPoints);
                        this.radar.endFill();

                        this.objects.forEach((object) => {
                            const toObject = object.posSAT.clone().sub(center)
                            const isInRange = toObject.len2() <= range2;

                            if (!isInRange) {
                                object.visible = false;
                                return;
                            }

                            const ray = toObject.clone().normalize();

                            const rayResult = new RaycastResult();
                            const points: SAT.Vector[] = [];

                            areasInRange.forEach((area) => {
                                switch (area.area.shape.type) {
                                    case 'circle':
                                        rayResult.reset();
                                        if (rayVSCircle(center, ray, area.shape.pos, area.shape.r, rayResult)) {
                                            points.push(rayResult.points[0]);
                                        }
                                }
                            });

                            const nearest = points.reduce((prev: SAT.Vector | null, point: SAT.Vector) => {
                                if (!prev) return point;

                                if (point.clone().sub(center).len2() < prev.clone().sub(center).len2()) {
                                    return point;
                                }

                                return prev;
                            }, null);

                            if (nearest && nearest.clone().sub(center).len2() <= toObject.len2()) {
                                object.visible = false;
                                return;
                            }

                            object.visible = true;
                        })

                    }
                })

            })


        }
    }



    add(data: GameObjectSchema): MapObject {
        const object = this.shipPool.allocate() as MapObject;
        object.type = data.type;

        this.addChild(object);
        this.objects.set(data.id, object);

        return object;
    }

    remove(id: string) {
        const ship = this.objects.get(id);

        if (ship) {
            this.shipPool.release(ship);
            this.removeChild(ship);

            this.objects.delete(id);
        }

        this.sources.delete(id);
    }
}


