import { useGame } from "../../modules/server";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import styled from "styled-components";
import Grid from "./grid";
import PageHeader from "../../components/header";

type Props = {}

const Container = styled.div`
    width: 100%;

    @keyframes shield-damaged {
        from {
            background-color: blue;
        }
        to {
            background-color: #676767;
        }
    }

    @keyframes shield-downed {
        from {
            background-color: yellow;
        }
        to {
            background-color: #676767;
        }
    }

    @keyframes hull-damaged {
        from {
            background-color: red;
        }
        to {
            background-color: #676767;
        }
    }

    .panels-container {

        &.shield-downed {
            animation: shield-downed 1s;
        }

        &.shield-damaged {
            animation: shield-damaged 1s;
        }

        &.hull-damaged {
            animation: hull-damaged 1s;
        }

    }

    .console-wrapper {
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
    }

    .layout-controller {
        padding: 0.5em;
        margin: 1em 0;
        background: #676767;
    }
`;

const Game: React.FC<Props> = () => {

    const { isConnected } = useGame();

    return <Container>
        <PageHeader />
        {
            isConnected && <Grid />
        }
        {
            !isConnected && <p>Łączę....</p>
        }
    </Container>;
}

export default Game;
