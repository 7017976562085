import { MapSchema } from '@colyseus/schema';
import { useEffect, useReducer } from 'react';

type Action<T> = {
    type: 'init',
    data: MapSchema<T>
} | {
    type: 'add' | 'remove',
    data: T,
    key: string
}

export default function useMapSchema<T>(schema?: MapSchema<T>) {
    const [value, change] = useReducer((state: T[], action: Action<T>) => {

        if (action.type === 'init') {
            const result: T[] = [];

            action.data.forEach(p => {
                result.push(p);
            })

            return result;
        }

        if (action.type === 'add') {

            return [
                //@ts-ignore
                ...state.filter( i => i.id !== action.key),
                action.data
            ];
        }

        if (action.type === 'remove') {
            return state.filter(i => i !== action.data);
        }

        return state;
    }, []);

    useEffect(() => {
        if (schema) {
            change({ type: 'init', data: schema });

            schema.onAdd = (item, key) => {
                change({ type: 'add', data: item, key })
            }

            schema.onRemove = (item, key) => {
                change({ type: 'remove', data: item, key })
            }
        }
    }, [schema, change]);

    return value;
}
