import { RoomAvailable } from "colyseus.js";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getGameAdminRoute, getGameRoute } from "../routes";

type Props = {
    room: RoomAvailable
}

const Container = styled.div`
    .inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 90%;
    }
`;

const InProgressGame: React.FC<Props> = ({ room }) => {

    return <Container className="box">
        <div className="inner">
            <h3>Gra: {room.roomId}</h3>
            <Link to={getGameRoute(room.roomId)}>Wejdź</Link>
            <Link to={getGameAdminRoute(room.roomId)}>Obserwuj</Link>
        </div>

    </Container>
}

export default InProgressGame;
