import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import styled from "styled-components";
import useCrew from "../../modules/server/hooks/game/panels/use-crew";
import { GameUserSchema } from "../../schemas/GameUserSchema";
import { PlayerSlotSchema } from "../../schemas/PlayerSlotSchema";
import { ShipSchema } from "../../schemas/ShipSchema";

type Props = {
    ship: ShipSchema;
}

const Container = styled.div`

`;

const CrewController: React.FC<Props> = ({ ship }) => {
    const { crew, slots, updateCrew } = useCrew(ship);

    const formMethods = useForm();
    const { handleSubmit } = formMethods;

    const [mode, setMode] = useState<'view' | 'edit'>('view');

    const onSubmit = useCallback((data) => {
        console.log(data);
        updateCrew(data);
        setMode('view');
    }, [updateCrew, setMode]);

    return <Container>
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <table>
                    <tbody>
                        <tr>
                            <td>Nick</td>
                            <td>Stanowisko</td>
                        </tr>
                        {mode === 'view' && crew.map(user => {
                            return <UserRow key={user.id} user={user} slots={slots} />
                        })}
                        {mode === 'edit' && crew.map(user => {
                            return <UserEditRow key={user.id} user={user} slots={slots} />
                        })}
                    </tbody>
                </table>
                {mode === 'view' && <div>
                    <button onClick={() => { setMode('edit') }}>Zamień stanowiska</button>
                </div>}
                {mode === 'edit' && <div>
                    <button onClick={() => { setMode('view') }}>Anuluj</button>
                    <input type='submit' value="zapisz" />
                </div>}
            </form>
        </FormProvider>
    </Container>
}

export default CrewController;


const UserRow: React.FC<{
    user: GameUserSchema,
    slots: PlayerSlotSchema[]
}> = ({ user, slots }) => {

    const slotName = useMemo(() => {
        return slots.find(s => s.id === user.slotId)?.name || '';
    }, [user, slots]);

    return <tr>
        <td>{user.name}</td>
        <td>{slotName}</td>
    </tr>
}


const UserEditRow: React.FC<{
    user: GameUserSchema,
    slots: PlayerSlotSchema[]
}> = ({ user, slots }) => {

    const { register } = useFormContext();

    return <tr>
        <td>{user.name} {user.id}</td>
        <td>
            <select defaultValue={user.slotId} {...register(user.id)}>
                {
                    slots.map(s => {
                        return <option key={s.id} value={s.id}>
                            {s.name}
                        </option>
                    })
                }
            </select>
        </td>
    </tr>
}
