/*
Lista wszystkich dostepnych paneli
*/

import GUI2Controlls from "../../components/gui-v2/controlls";
import UICargo from "../../components/player-ui/cargo";
import UICrew from "../../components/player-ui/crew";
import UIDecoys from "../../components/player-ui/decoys";
import UIEnergyRadar from "../../components/player-ui/energy-radar";
import UIMainEngine from "../../components/player-ui/main-engine";
import UIMic from "../../components/player-ui/mic";
import UIMissionComs from "../../components/player-ui/mission-coms";
import UIObjectInfo from "../../components/player-ui/object-info";
import UIRadar from "../../components/player-ui/radar";
import UIReactor from "../../components/player-ui/reactor";
import UIScanner from "../../components/player-ui/scanner";
import UIShields from "../../components/player-ui/shields";
import UIShipStatus from "../../components/player-ui/status";
import UITorpedoControll from "../../components/player-ui/torpedo-controll";
import UITorpedos from "../../components/player-ui/torpedos";
import UITrackingList from "../../components/player-ui/tracking-list";
import UITractorBeam from "../../components/player-ui/tractor-beam";

const PANELS: {
    id: string,
    name: string,
    item: React.ReactFragment
}[] = [
        {
            id: "radar",
            name: 'Radar',
            item: <UIRadar />
        },
        {
            id: "main-engine",
            name: 'Silnik główny',
            item: <UIMainEngine />
        },
        {
            id: "object-info",
            name: 'Informacja o obiekcie',
            item: <UIObjectInfo />
        },
        {
            id: "scanner",
            name: 'Skaner',
            item: <UIScanner />
        },
        {
            id: "tracking-list",
            name: 'Lista śledzonych obiektów',
            item: <UITrackingList />
        },
        {
            id: 'reactor',
            name: 'Reaktor',
            item: <UIReactor />
        },
        {
            id: 'status',
            name: 'Uszkodzenia',
            item: <UIShipStatus />
        },
        {
            id: 'torpedos',
            name: 'Torpedownia',
            item: <UITorpedos />
        },
        {
            id: 'torpedo-controll',
            name: "Kontrola torpedy",
            item: <UITorpedoControll />
        },
        {
            id: 'decoys',
            name: "Wabie",
            item: <UIDecoys />
        },
        {
            id: 'shields',
            name: "Tarcze",
            item: <UIShields />
        },
        {
            id: 'tractor-beam',
            name: 'Promień ściągający',
            item: <UITractorBeam />
        },
        {
            id: 'cargo',
            name: "Ładownia",
            item: <UICargo />
        },
        {
            id: 'mission-coms',
            name: 'Misja',
            item: <UIMissionComs />
        }, {
            id: 'energy-radar',
            name: 'Radar energetyczny',
            item: <UIEnergyRadar />
        }, {
            id: 'mic',
            name: 'Mikrofon',
            item: <UIMic />
        }, {
            id: 'crew',
            name: 'Załoga',
            item: <UICrew />
        }, {
            id: 'v2-controlls',
            name: '[v2] Sterowanie/Prędkość',
            item: <GUI2Controlls />
        }
    ]


export default PANELS;
