import styled from "styled-components";
import useDistance from "../../../modules/server/hooks/game/use-distance";
import useGameObject from "../../../modules/server/hooks/game/use-game-object";

type Props = {
    id: string
}

const Container = styled.div`

`;

const InfoBox: React.FC<Props> = ({ id }) => {

    const { gameObject } = useGameObject(id);
    const distance = useDistance(id);

    return <Container>
        {gameObject && <>
            {gameObject.type === 'ship' && <div>
                <strong>Statek: {
                        //@ts-ignore
                        gameObject.name
                    }
                </strong><br />
                Dystans: {distance | 0}
            </div>}
            {gameObject.type === 'asteroid' && <div>
                <strong>Asteroida:  {
                        gameObject.id
                    }
                </strong><br />
                Dystans: {distance | 0}<br />
            </div>}
            {gameObject.type === 'container' && <div>
                <strong>Kontener: {
                        gameObject.id
                    }
                </strong><br />
                Dystans: {distance | 0}<br />
            </div>}
            {gameObject.type === 'space-station' && <div>
                <strong>Stacja kosmiczna: {
                        //@ts-ignore
                        gameObject.name
                    }
                </strong><br />
                Dystans: {distance | 0}<br />
            </div>}
        </>}
    </Container>
}

export default InfoBox;
