import { useCallback } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import useLogin from "../../modules/main/hooks/use-login";

type Props = {}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;

    .row {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem 0;
    }

    input {
        font-size: 1.4rem;
        padding: 0.5rem;
        border-radius: .5rem;
        text-align: center;
    }
`;

const LoginForm: React.FC<Props> = () => {

    const { login } = useLogin()

    const { register, handleSubmit } = useForm();

    const onSubmit = useCallback((data: any) => {
        login({ login: data.name });
    }, [login])

    return <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <input type="text" id="" {...register('name', { required: true })} placeholder='login' />
            </div>
            <div className="row">
                <button type="submit">ZALOGUJ</button>
            </div>
        </form>
    </Container>
}

export default LoginForm;
