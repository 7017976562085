import { useMemo } from "react";
import { useGame, useLocalState, useMyShip } from "../../..";
import useSetSchema from "../../use-set-schema";
export default function useObjectInfo() {
    const { ship } = useMyShip();
    const { selectedObject, adminMode } = useLocalState();
    const tracked = useSetSchema(ship?.tracking.trackedObjects);
    const {gameObjects} = useGame();

    const object = useMemo(() => {
        if (!selectedObject) return null;

        if (ship?.id === selectedObject) {
            return {
                id: ship.id,
                known: true
            };
        }

        if (tracked.includes(selectedObject) || gameObjects.find(go => go.id === selectedObject)?.type === 'space-station') {
            return {
                id: selectedObject,
                known: true
            }
        }

        return {
            id: selectedObject,
            known: adminMode
        }


    }, [selectedObject, tracked, ship, gameObjects, adminMode])


    return {
        object
    }
}
