import { useParams } from "react-router"
import { GameLobbyProvider } from "../../modules/server";
import GameLobby from "./lobby";

type Props = {

}

const GameLobbyPage: React.FC<Props> = () => {

    const { id } = useParams<{ id: string }>();

    return <GameLobbyProvider id={id}>
        <GameLobby />
    </GameLobbyProvider>
}

export default GameLobbyPage
