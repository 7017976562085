import * as PIXI from 'pixi.js';
import { first, Subject, takeUntil } from 'rxjs';
import { AreaSchema } from '../../../schemas/AreaSchema';
import { GameObjectSchema } from '../../../schemas/GameObjectSchema';
import { WidgetConfig } from '../config';
import AreaLayer from './area-layer';
import Follower from './follower';
import NavLayer from './layer-nav';
import ObjectsLayer from './layer-objects';
import TopoLayer from './layer-topo';
import { CRTFilter } from '@pixi/filter-crt';
import EnergyLayer from './layer-energy';

export type UpdateData = {
    gameObjects: GameObjectSchema[],
    shipId?: string,
    tracked: string[],
    areas: AreaSchema[],
    selectedObject?: string
}

export default class PixiApp extends PIXI.Application {

    private debugText = new PIXI.Text('');

    private updateInterval?: number;

    private follower = new Follower();

    constructor(width: number, height: number, private config: WidgetConfig, options?: PIXI.IApplicationOptions) {
        super({
            width,
            height,
            backgroundColor: 0x030e32,
            antialias: true,
            resizeTo: config.container,
            ...(options || {})
        });

        console.log('INIT App', this.config);

        this.renderer.view.style.width = '100%';
        this.renderer.view.style.height = '100%';


        this.loadAssets([
            ['asteroids', '/images/game/asteroids.png']
        ]).then((resources) => {
            this.initWidget();
        })
    }

    private layerTopo?: TopoLayer;
    private layerNav?: NavLayer;
    private layerObjects?: ObjectsLayer;
    private layerEnergy?: EnergyLayer;

    private layerAreas?: AreaLayer;

    private layerUI = new PIXI.Container();

    public lootAt: [number, number] = [-15, -35];

    public globalLayers: PIXI.Container[] = []

    private initWidget() {
        this.debugText.position.set(5, 100);

        this.layerTopo = new TopoLayer(this);

        this.layerNav = new NavLayer();
        this.layerNav.update(Math.min(this.renderer.width, this.renderer.height), this.mapZoom);
        this.layerNav.position.set(this.renderer.width / 2, this.renderer.height / 2);

        this.layerObjects = new ObjectsLayer(this.config.adminMode || false);
        this.layerEnergy = new EnergyLayer();

        this.layerObjects.on('object-selected', ({ objectId }) => {
            if (this.config.onObjectSelected) {
                this.config.onObjectSelected(objectId);
            }
        })


        this.layerAreas = new AreaLayer();


        // this.globalLayers.push(this.layerTopo);
        this.globalLayers.push(this.layerObjects);
        this.globalLayers.push(this.layerEnergy);
        this.globalLayers.push(this.layerAreas);

        this.stage.addChild(this.layerTopo);
        this.stage.addChild(this.layerNav);
        this.stage.addChild(this.layerAreas);
        this.stage.addChild(this.layerObjects);
        this.stage.addChild(this.layerEnergy);
        this.stage.addChild(this.layerUI);


        const crt = new CRTFilter({
            lineWidth: 10,
            time: .5,
            curvature: 20,
            lineContrast: 1
        });

        // this.stage.filters = [crt];

        setInterval(() => {
            crt.time += .5;
        }, 100)

        this.layerUI.addChild(this.debugText);



        this.updateInterval = window.setInterval(() => {
            this.resize();
            this._update();
        }, 1000)

        if (this.config.centerOn) {
            this.startFollow(this.config.centerOn);
        }

        this.follower.$lookAt
            .pipe(takeUntil(this.$destroy))
            .subscribe(xy => {
                this.lootAt = xy;
                this._update();
            })

        if (this.lastUpdate) {
            this.update(this.lastUpdate);
        }

        this.initDragging();

        this._update();
    }

    private loadAssets(assets: any[]) {

        return new Promise(resolve => {
            assets.forEach(assetInfo => {
                //@ts-ignore
                this.loader.add(...assetInfo);
            })

            this.loader.load((loader, resource) => {
                resolve(null);
            })

            this.loader.onError.add((...args: any[]) => {
                console.error(...args);
            })
        });

    }

    private _update() {
        if (this.layerTopo) {
            this.layerTopo.update(this.lootAt, this.renderer.width, this.renderer.height, this.mapZoom);
            this.layerTopo.position.set(this.renderer.width / 2, this.renderer.height / 2)
            this.layerTopo.pivot.set(
                this.lootAt[0] / this.mapZoom,
                this.lootAt[1] / this.mapZoom
            );
        }

        if (this.layerNav) {
            this.layerNav.update(Math.min(this.renderer.width, this.renderer.height), this.mapZoom);
            this.layerNav.position.set(this.renderer.width / 2, this.renderer.height / 2);
        }

        this.globalLayers.forEach(layer => {
            layer.position.set(this.renderer.width / 2, this.renderer.height / 2)
            layer.pivot.set(
                this.lootAt[0],
                this.lootAt[1]
            );
            layer.scale.set(1 / this.mapZoom);
        })

        if (this.lastUpdate)
            this.$update.next(this.lastUpdate);
    }

    private $stopFollow = new Subject();

    public startFollow(id: string) {
        this.$update
            .pipe(first())
            .subscribe(data => {
                if (id !== this.follower.followingId) {
                    this.follower.stopFollow();
                    const target = data.gameObjects.find(go => go.id === id);

                    if (target) {
                        this.follower.startFollow(target);
                    }
                }
            })
    }

    public stopFollow() {
        this.$stopFollow.next(null);
        this.follower.stopFollow();
    }

    private mapZoom = 1;

    private setZoom(z: number) {
        this.mapZoom = Math.max(z, .1);
        // this.debugText.text = `zoom: ${this.mapZoom}`
        this._update();
    }

    private $update = new Subject<UpdateData>()

    private lastUpdate?: any;

    public update(data: UpdateData) {
        this.lastUpdate = data;
        if (this.config.showRadar && this.layerObjects) {
            this.layerObjects.update(data);
        }
        if (this.config.showEnergy && this.layerEnergy) {
            this.layerEnergy.update(data);
        }
        if (this.config.showRadar && this.layerAreas) {
            this.layerAreas.update(data);
        }

        this.$update.next(data);
    }

    private $destroy = new Subject();

    public destroy(removeView: boolean = false) {
        super.destroy(removeView)
        console.log('destroy APP');
        window.clearInterval(this.updateInterval);
        this.$destroy.next(null);
    }

    public zoomIn() {
        this.setZoom(this.mapZoom - .1);
    }

    public zoomOut() {
        this.setZoom(this.mapZoom + .1);
    }

    public zoomReset() {
        this.setZoom(1);
    }

    public moveUp() {
        this.stopFollow();
        this.lootAt[1] -= 10 * this.mapZoom;
        this._update();
    }

    public moveDown() {
        this.stopFollow();
        this.lootAt[1] += 10 * this.mapZoom;
        this._update();
    }

    public moveLeft() {
        this.stopFollow();
        this.lootAt[0] -= 10 * this.mapZoom;
        this._update();

    }

    public moveRight() {
        this.stopFollow();
        this.lootAt[0] += 10 * this.mapZoom;
        this._update();

    }

    public centerView() {
        if (this.config.centerOn) {
            this.startFollow(this.config.centerOn);
            this._update();
        }
    }

    public centerOn(id: string) {
        this.config.centerOn = id;
        this.startFollow(id);
        this._update();
    }

    private dragStart: PIXI.Point | null = null;

    private initDragging() {
        this.stage.interactive = true;
        this.stage.hitArea = new PIXI.Rectangle(0,0,this.renderer.width, this.renderer.height);

        this.stage.on('pointerdown', (e: PIXI.InteractionEvent) => {

            const grabPosition = e.data.getLocalPosition(this.stage);
            this.dragStart = grabPosition;
            this.follower.stopFollow();
        });

        this.stage.on('pointermove', (e: PIXI.InteractionEvent) => {
            if (this.dragStart) {
                const movePosition = e.data.getLocalPosition(this.stage);

                this.lootAt[0] += (-movePosition.x + this.dragStart.x) * this.mapZoom;
                this.lootAt[1] += (-movePosition.y + this.dragStart.y) * this.mapZoom;
                this.dragStart = movePosition;
                this._update();
            }
        });

        this.stage.on('pointerup', () => {
            this.dragStart = null;
        })

        this.stage.on('pointerout', () => {
            this.dragStart = null;
        })

        this.stage.on('mousewheel', () => {
            console.log('mousewheel');
        })
    }
}
