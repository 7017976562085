import RaycastResult from "./result";
import * as SAT from 'sat';

/**
 * Based on: https://www.youtube.com/watch?v=23kTf-36Fcw
 */
export function rayVSCircle(rOrigin: SAT.Vector, rDirection: SAT.Vector,  cOrigin: SAT.Vector, radius: number, result?: RaycastResult) {
    const toCircle = cOrigin.clone().sub(rOrigin);
    const r2 = radius * radius;
    const toCircleLength2 = toCircle.len2();

    const a = toCircle.dot(rDirection);
    const b2 = toCircleLength2 - (a*a);

    if (r2 - b2 < 0) {
        return false;
    }

    const f = Math.sqrt(r2 - b2);
    let t = 0;

    if (toCircleLength2 < r2) {
        t = a + f;
    } else {
        t = a - f;
    }

    if (t < 0) return false;

    if (result) {
        const point = rOrigin.clone().add(rDirection.clone().scale(t))
        // const normal = point.clone().sub(cOrigin).normalize();

        result.points.push(point)
    }

    return true;

}
