import styled from "styled-components";
import LoginForm from "../../components/login-form";
import bgSrc from '../../assets/images/bg-1.jpg';

const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: url(${bgSrc}) 50% 50% no-repeat;
    background-size: cover;

    color: #0c0a32;
    font-family: 'Jura', sans-serif;

    .box {
        border: 1px solid black;
        padding: 1em;
        border-radius: 17px;
        max-width: 600px;
        width: 95%;

        background: white;
        background-image: linear-gradient(0deg, rgba(0,0,0,0.28) 0%, rgba(255,255,255,0.28) 100%);
    }

    h1 {
        text-align: center;
        padding: 0 0 1em;
        margin: 0 0 1em;
        width: 100%;

        border-bottom: 1px solid black;
    }
`;

export default function LoginPage() {
    return <Container>
        <div className="box">
            <h1>Logowanie</h1>
            <LoginForm />
        </div>
    </Container>
}
