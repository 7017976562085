import { useCallback, useMemo } from "react";
import { useGame, useMyShip } from "../../..";
import { ShieldLevel } from "../../../../../schemas/ShieldLevel";
import { ShieldSchema } from "../../../../../schemas/ShieldSchema";
import { ShieldsSchema } from "../../../../../schemas/ShieldsSchema";
import useBasicSchema from "../../use-basic-schema";

export default function useShields() {
    const { ship } = useMyShip();
    const { room } = useGame();

    const shieldsPoints = useBasicSchema<ShieldsSchema, number>(ship?.shields, 'shieldsPoints');

    const frontLevel = useBasicSchema<ShieldSchema, number>(ship?.shields.front, 'level');

    const front1isActive = useBasicSchema<ShieldLevel, boolean>(ship?.shields.front.level1, 'isActive');
    const front2isActive = useBasicSchema<ShieldLevel, boolean>(ship?.shields.front.level2, 'isActive');
    const front3isActive = useBasicSchema<ShieldLevel, boolean>(ship?.shields.front.level3, 'isActive');

    const front1isFreeze = useBasicSchema<ShieldLevel, boolean>(ship?.shields.front.level1, 'isFreeze');
    const front2isFreeze = useBasicSchema<ShieldLevel, boolean>(ship?.shields.front.level2, 'isFreeze');
    const front3isFreeze = useBasicSchema<ShieldLevel, boolean>(ship?.shields.front.level3, 'isFreeze');

    const front1maxHealth = useBasicSchema<ShieldLevel, number>(ship?.shields.front.level1, 'maxHealth');
    const front2maxHealth = useBasicSchema<ShieldLevel, number>(ship?.shields.front.level2, 'maxHealth');
    const front3maxHealth = useBasicSchema<ShieldLevel, number>(ship?.shields.front.level3, 'maxHealth');

    const front1health = useBasicSchema<ShieldLevel, number>(ship?.shields.front.level1, 'health');
    const front2health = useBasicSchema<ShieldLevel, number>(ship?.shields.front.level2, 'health');
    const front3health = useBasicSchema<ShieldLevel, number>(ship?.shields.front.level3, 'health');

    const backLevel = useBasicSchema<ShieldSchema, number>(ship?.shields.back, 'level');


    const back1isActive = useBasicSchema<ShieldLevel, boolean>(ship?.shields.back.level1, 'isActive');
    const back2isActive = useBasicSchema<ShieldLevel, boolean>(ship?.shields.back.level2, 'isActive');
    const back3isActive = useBasicSchema<ShieldLevel, boolean>(ship?.shields.back.level3, 'isActive');

    const back1isFreeze = useBasicSchema<ShieldLevel, boolean>(ship?.shields.back.level1, 'isFreeze');
    const back2isFreeze = useBasicSchema<ShieldLevel, boolean>(ship?.shields.back.level2, 'isFreeze');
    const back3isFreeze = useBasicSchema<ShieldLevel, boolean>(ship?.shields.back.level3, 'isFreeze');

    const back1maxHealth = useBasicSchema<ShieldLevel, number>(ship?.shields.back.level1, 'maxHealth');
    const back2maxHealth = useBasicSchema<ShieldLevel, number>(ship?.shields.back.level2, 'maxHealth');
    const back3maxHealth = useBasicSchema<ShieldLevel, number>(ship?.shields.back.level3, 'maxHealth');

    const back1health = useBasicSchema<ShieldLevel, number>(ship?.shields.back.level1, 'health');
    const back2health = useBasicSchema<ShieldLevel, number>(ship?.shields.back.level2, 'health');
    const back3health = useBasicSchema<ShieldLevel, number>(ship?.shields.back.level3, 'health');

    const leftLevel = useBasicSchema<ShieldSchema, number>(ship?.shields.left, 'level');

    const left1isActive = useBasicSchema<ShieldLevel, boolean>(ship?.shields.left.level1, 'isActive');
    const left2isActive = useBasicSchema<ShieldLevel, boolean>(ship?.shields.left.level2, 'isActive');
    const left3isActive = useBasicSchema<ShieldLevel, boolean>(ship?.shields.left.level3, 'isActive');

    const left1isFreeze = useBasicSchema<ShieldLevel, boolean>(ship?.shields.left.level1, 'isFreeze');
    const left2isFreeze = useBasicSchema<ShieldLevel, boolean>(ship?.shields.left.level2, 'isFreeze');
    const left3isFreeze = useBasicSchema<ShieldLevel, boolean>(ship?.shields.left.level3, 'isFreeze');

    const left1maxHealth = useBasicSchema<ShieldLevel, number>(ship?.shields.left.level1, 'maxHealth');
    const left2maxHealth = useBasicSchema<ShieldLevel, number>(ship?.shields.left.level2, 'maxHealth');
    const left3maxHealth = useBasicSchema<ShieldLevel, number>(ship?.shields.left.level3, 'maxHealth');

    const left1health = useBasicSchema<ShieldLevel, number>(ship?.shields.left.level1, 'health');
    const left2health = useBasicSchema<ShieldLevel, number>(ship?.shields.left.level2, 'health');
    const left3health = useBasicSchema<ShieldLevel, number>(ship?.shields.left.level3, 'health');

    const rightLevel = useBasicSchema<ShieldSchema, number>(ship?.shields.right, 'level');

    const right1isActive = useBasicSchema<ShieldLevel, boolean>(ship?.shields.right.level1, 'isActive');
    const right2isActive = useBasicSchema<ShieldLevel, boolean>(ship?.shields.right.level2, 'isActive');
    const right3isActive = useBasicSchema<ShieldLevel, boolean>(ship?.shields.right.level3, 'isActive');

    const right1isFreeze = useBasicSchema<ShieldLevel, boolean>(ship?.shields.right.level1, 'isFreeze');
    const right2isFreeze = useBasicSchema<ShieldLevel, boolean>(ship?.shields.right.level2, 'isFreeze');
    const right3isFreeze = useBasicSchema<ShieldLevel, boolean>(ship?.shields.right.level3, 'isFreeze');

    const right1maxHealth = useBasicSchema<ShieldLevel, number>(ship?.shields.right.level1, 'maxHealth');
    const right2maxHealth = useBasicSchema<ShieldLevel, number>(ship?.shields.right.level2, 'maxHealth');
    const right3maxHealth = useBasicSchema<ShieldLevel, number>(ship?.shields.right.level3, 'maxHealth');

    const right1health = useBasicSchema<ShieldLevel, number>(ship?.shields.right.level1, 'health');
    const right2health = useBasicSchema<ShieldLevel, number>(ship?.shields.right.level2, 'health');
    const right3health = useBasicSchema<ShieldLevel, number>(ship?.shields.right.level3, 'health');

    const front1states = useShieldLevelStates(front1isActive, front1isFreeze, front1health, front1maxHealth)
    const front2states = useShieldLevelStates(front2isActive, front2isFreeze, front2health, front2maxHealth)
    const front3states = useShieldLevelStates(front3isActive, front3isFreeze, front3health, front3maxHealth)

    const back1states = useShieldLevelStates(back1isActive, back1isFreeze, back1health, back1maxHealth)
    const back2states = useShieldLevelStates(back2isActive, back2isFreeze, back2health, back2maxHealth)
    const back3states = useShieldLevelStates(back3isActive, back3isFreeze, back3health, back3maxHealth)

    const left1states = useShieldLevelStates(left1isActive, left1isFreeze, left1health, left1maxHealth)
    const left2states = useShieldLevelStates(left2isActive, left2isFreeze, left2health, left2maxHealth)
    const left3states = useShieldLevelStates(left3isActive, left3isFreeze, left3health, left3maxHealth)

    const right1states = useShieldLevelStates(right1isActive, right1isFreeze, right1health, right1maxHealth)
    const right2states = useShieldLevelStates(right2isActive, right2isFreeze, right2health, right2maxHealth)
    const right3states = useShieldLevelStates(right3isActive, right3isFreeze, right3health, right3maxHealth)

    const setShieldLevel = useCallback((site: 'front' | 'back' | 'left' | 'right', value: number) => {
        if (room) {
            room.send('set-shield-level', {
                site,
                value
            })
        }
    }, [room]);

    return {
        shieldsPoints,

        frontLevel,
        front1isActive,
        front2isActive,
        front3isActive,
        front1isFreeze,
        front2isFreeze,
        front3isFreeze,
        front1maxHealth,
        front2maxHealth,
        front3maxHealth,
        front1health,
        front2health,
        front3health,
        front1states,
        front2states,
        front3states,

        backLevel,
        back1isActive,
        back2isActive,
        back3isActive,
        back1isFreeze,
        back2isFreeze,
        back3isFreeze,
        back1maxHealth,
        back2maxHealth,
        back3maxHealth,
        back1health,
        back2health,
        back3health,
        back1states,
        back2states,
        back3states,

        leftLevel,
        left1isActive,
        left2isActive,
        left3isActive,
        left1isFreeze,
        left2isFreeze,
        left3isFreeze,
        left1maxHealth,
        left2maxHealth,
        left3maxHealth,
        left1health,
        left2health,
        left3health,
        left1states,
        left2states,
        left3states,

        rightLevel,
        right1isActive,
        right2isActive,
        right3isActive,
        right1isFreeze,
        right2isFreeze,
        right3isFreeze,
        right1maxHealth,
        right2maxHealth,
        right3maxHealth,
        right1health,
        right2health,
        right3health,
        right1states,
        right2states,
        right3states,

        setShieldLevel

    }
}


function useShieldLevelStates(active: boolean = false, freeze: boolean = false, health: number = 0, maxHealth: number = 0) {
    return useMemo(() => {
        const result: string[] = [];

        result.push(active ? 'on' : 'off');

        if (freeze) {
            result.push('freeze');
        }

        if (health === maxHealth) {
            result.push('full');
        }

        return result;
    }, [active, freeze, health, maxHealth]);
}
