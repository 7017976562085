import { Layer } from '@pixi/layers';
import * as PIXI from 'pixi.js';
import * as SAT from 'sat';

const COLOR = 0x3ec4fb

export default class MapObject extends PIXI.Container {

    private gfx = new PIXI.Graphics();
    private text = new PIXI.Text('?', { fill: 0x3ec4fb });

    private _isTracked = false;
    private _isMe = false;

    public _type: string = '';

    set type(v: string) {
        this._type = v;
        this.update();
    }

    public posSAT = new SAT.Vector();

    public selected = false;

    set isTracked(value: boolean) {
        if (this._isTracked !== value) {
            this._isTracked = value;
            this.update();
        }
    }

    set isMe(value: boolean) {
        if (this._isMe !== value) {
            this._isMe = value;
            this.update();
        }
    }

    get isMe() {
        return this._isMe;
    }


    constructor() {
        super();


        this.text.visible = false;
        this.text.anchor.set(.5);

        this.addChild(this.gfx);
        this.addChild(this.text);

        this.hitArea = new PIXI.Rectangle(-10, -10, 20, 20);

        this.update();
    }

    set lightingLayer(v: Layer) {
        this.gfx.parentLayer = v;
    }

    update() {
        this.gfx.clear();

        this.gfx.beginFill(COLOR, 1.0);
        this.gfx.drawCircle(0, 0, 10);
        this.gfx.endFill();

    }

    public setX(x: number) {
        this.x = x;
        this.posSAT.x = x;
    }

    public setY(y: number) {
        this.y = y;
        this.posSAT.y = y;
    }

    public setXY(x: number, y: number) {
        this.x = x;
        this.y = y;

        this.posSAT.x = x;
        this.posSAT.y = y;
    }
}
