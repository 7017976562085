import { useForm } from "react-hook-form";
import styled from "styled-components";

type Props = {
    items: { id: string, name: string, visible?: boolean }[],
    onChange(value: string[]): void,
    layoutInfo: any
}

const Container = styled.div`
    width: 95%;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;

    &>* {
        flex: 0 0 45%;
    }

    textarea {
        width: 100%;
        min-height: 10em;
    }
`;

const StationController: React.FC<Props> = ({ items, onChange, layoutInfo }) => {

    const { register, watch } = useForm();

    watch((data) => {
        const list = Object.keys(data).reduce((prev: string[], key) => {
            if (data[key]) {
                return [
                    ...prev,
                    key
                ]
            }

            return prev;
        }, [])

        onChange(list);
    });

    return <Container>
        <div>
            {
                items.map((i) => {
                    return <div key={i.id}>
                        <label>
                            <input type="checkbox" value={i.id} {...register(i.id, { value: i.visible })} /> {i.name}
                        </label>
                    </div>
                })
            }
        </div>
        <div>
            <textarea readOnly={true} value={JSON.stringify(layoutInfo, null, 4)} />
        </div>
    </Container>
}

export default StationController;
