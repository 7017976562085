import { useCallback, useEffect, useState } from "react";
import { useServer } from "../..";
import useGameRooms from "../use-game-rooms";
import { useHistory } from "react-router";
import { getGameLobbyRoute } from "../../../../routes/routes";
import useConfig from "../../../main/hooks/use-config";
import { Room } from "colyseus.js";

export default function useLobby() {
    const { gameServer } = useServer();
    const { gameAuthToken } = useConfig();

    const { rooms, refresh, inProgressRooms } = useGameRooms();
    const history = useHistory();
    const [room, setRoom] = useState<Room | null>(null);

    useEffect(() => {
        gameServer.joinOrCreate('lobby', {
            token: gameAuthToken
        })
            .then(lobby => {
                setRoom(lobby)
            })
    }, [gameServer, gameAuthToken, setRoom]);

    useEffect(() => {
        if (room) {
            room.onMessage('*', (type, msg) => {
                refresh();
            });

            return () => {
                room.removeAllListeners();
            }
        }
    }, [room, refresh]);

    const create = useCallback(() => {
        gameServer.create('game-lobby')
            .then(room => {
                const id = room.id;
                setTimeout(() => {
                    history.push(getGameLobbyRoute(id));
                }, 0);
            })
    }, [gameServer, history]);

    const join = useCallback((id: string) => {
        gameServer.joinById(id).then(() => {
            history.push(getGameLobbyRoute(id));
        })
    }, [gameServer, history]);

    return {
        room,
        rooms,
        inProgressRooms,
        refresh,
        create,
        join
    }
}
