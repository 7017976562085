import { useGame, useLocalState, useMyShip } from "../../..";
import useSetSchema from "../../use-set-schema";

export default function useRadar() {
    const { ship } = useMyShip();
    const { gameObjects, areas } = useGame();
    const {selectObject, selectedObject} = useLocalState();

    const tracked = useSetSchema(ship?.tracking.trackedObjects);

    return {
        shipId: ship?.id,
        gameObjects,
        tracked,
        areas,
        selectObject,
        selectedObject
    }
}
