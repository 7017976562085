import React from "react";
import styled from "styled-components"

type Props = {}

const Container = styled.div`
    text-transform: uppercase;
    font-weight: bold;

    display: flex;
    align-items: center;

    &:before {
        display: inline-block;
        content: '';
        height: 1em;
        width: .5em;
        border: 1px solid black;
        border-left: none;
        background: #94F6FF;
        margin-right: 1em;
    }

`;

const BotTitle: React.FC<Props> = ({children}) => {
    return <Container>
        {children}
    </Container>
}

export default BotTitle;
