export function getHomeRoute() {
    return '/'
}

export function getLoginRoute() {
    return '/login'
}

export function getServerLobbyRoute() {
    return '/server-lobby';
}
export function getGameLobbyRoute(id: string = ':id') {
    return '/game-lobby/' + id;
}

export function getGameRoute(id: string = ':id') {
    return '/gra/' + id;
}

export function getGameAdminRoute(id: string = ':id') {
    return '/admin/' + id;
}
