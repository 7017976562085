import { useCallback } from "react";
import { useGame, useLocalState, useMyShip } from "../../..";
import useSetSchema from "../../use-set-schema";

export default function useTrakedList() {
    const { ship } = useMyShip();
    const { room } = useGame();
    const tracked = useSetSchema(ship?.tracking.trackedObjects);
    const {selectedObject,selectObject} = useLocalState();

    const cancelTracking = useCallback((id: string) => {
        if (room) {
            room.send('cancel-tracking', {
                targetId: id
            })
        }
    }, [room]);


    return {
        tracked,
        cancelTracking,
        selectedObject,
        selectObject
    }
}
