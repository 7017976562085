import React from "react";
import styled from "styled-components";
import useConfig from "../../modules/main/hooks/use-config";
import { useGameLobby } from "../../modules/server";
import useSetSchema from "../../modules/server/hooks/use-set-schema";
import { LobbyPlayerSlotSchema } from "../../schemas/LobbyPlayerSlotSchema";
import { LobbyShipSchema } from "../../schemas/LobbyShipSchema";
import PANELS from "../game/panels";

type Props = {
    gameId: string
}

const Container = styled.div`
    width: 100%;
    h2 {
        padding-bottom: .5em;
        border-bottom: 1px solid black;
        width: 100%;
        text-align: center;
    }

    .ship {
        padding: .5em;
        margin: 0.5em;
        background: #e1e1e1;
    }

    .team-list {
        display: flex;
        flex-direction: column
    }

    .team {
        border: 1px dashed black;
        margin: .5em 0;

        h4 {
            padding: .5em;
            margin: 0;
            text-align: center;
        }
    }

    .station {
        margin: 1em 0 1em 1em;
        border: 1px dotted black;
        padding: .5em;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &>* {
            flex: 0 0 30%;
        }
    }
`;

const ScenarioForm: React.FC<Props> = ({ gameId }) => {

    const { scenario } = useGameLobby();

    return <Container>
        {scenario && <>
            <h2>Scenariusz: {scenario.name}</h2>
            <div className="row">
                <h3>Opis scenariusza</h3>
                <p>{scenario.description}</p>
            </div>
            <div className="row">
                <h3>Drużyny i gracze</h3>
                <div className="team-list">
                    {
                        scenario.teams.map(team => {
                            return <div className="team" key={team.id}>
                                <h4>Drużyna: {team.name}</h4>
                                {
                                    scenario.playerShips
                                        .map(ps => {
                                            const ship = scenario.ships.find(s => (s.team === team.id && s.id === ps.id));

                                            if (ship) {
                                                return <div key={ps.id} className='ship'>
                                                    <h4>Statek: {ship.name}</h4>
                                                    <p>Stanowiska:</p>
                                                    <div>
                                                        {
                                                            ps.slots.map((slot) => {

                                                                return <div key={slot.id} className="station">
                                                                    <p style={{ marginBottom: ".5em" }}>Stanowisko: <strong>{slot.name}</strong></p>
                                                                    <div>
                                                                        <UsersInSlot slot={slot} ship={ship} />
                                                                    </div>
                                                                    <div>
                                                                        <h5>Dostępne panele:</h5>
                                                                        {slot.panels.length === 0 && 'Wszyskie'}
                                                                        {slot.panels.length > 0 && <div>
                                                                            {slot.panels.map(id => {
                                                                                return <div key={id}>{PANELS.find(p => p.id === id)?.name || id}</div>
                                                                            })}
                                                                        </div>}
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            }

                                            return <React.Fragment key={ps.id}></React.Fragment>
                                        })
                                }
                            </div>
                        })
                    }

                </div>
            </div>
        </>}
    </Container>

}

type UsersInSlotProps = {
    slot: LobbyPlayerSlotSchema,
    ship: LobbyShipSchema
}

const UsersInSlot: React.FC<UsersInSlotProps> = ({ slot, ship }) => {

    const { users, takeSlot, leaveSlot } = useGameLobby();
    const { user } = useConfig();
    const slotUsers = useSetSchema(slot.users);


    const showLeaveButton = slotUsers.includes(user.id);
    const showJoinButton = !showLeaveButton && (slot.multiplePlayersAllowed || slot.users.size === 0);

    return <div>
        <p>Gracze na stanowisku:</p>
        <div style={{ border: '1px solid black', padding: '1em', margin: '.5em 0' }}>
            {
                slotUsers.map(uId => {
                    const user = users.find(u => u.id === uId);

                    if (user) {
                        return <p key={uId}>{user.nickname}</p>
                    }

                    return <p key={uId}>Unknow user: {uId}</p>
                })
            }
            {
                slotUsers.length === 0 && <p style={{ textAlign: 'center' }}>-- Brak --</p>
            }
        </div>
        {
            showJoinButton && <button onClick={() => takeSlot(ship.id, slot.id)}>Zajmij stanowisko</button>
        }
        {
            showLeaveButton && <button onClick={() => leaveSlot()}>Opuść stanowisko</button>
        }
    </div>
}

export default ScenarioForm;
