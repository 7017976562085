import styled from "styled-components";
import useTractorBeam from "../../../modules/server/hooks/game/panels/use-tractor-beam";
import UIPanel from "../panel"

type Props = {}

const Container = styled.div`

`;

const UITractorBeam: React.FC<Props> = () => {

    const { targets, isPulling, pullingTarget, targetDistance, startPulling, cancelPulling } = useTractorBeam()

    return <UIPanel title="Promień ściągający">
        <Container>
            {isPulling && <div className="progress">
                Ściągamy: {pullingTarget}<br />
                Odległość: {targetDistance | 0}<br />
                <button onClick={cancelPulling}>Przerwij ściąganie</button>
            </div>}

            <div className="targets">
                <h3>Dostępne cele</h3>
                {
                    targets.map(t => {
                        return <div key={t.id}>{t.id}
                            <button onClick={() => startPulling(t.id)} disabled={isPulling}>Zacznij ściąganie</button>
                        </div>
                    })
                }
            </div>
        </Container>
    </UIPanel>
}

export default UITractorBeam;
