import MapMasterComponent from "../../map-master-component";
import UIPanel from "../panel"

type Props = {}

const UIRadar: React.FC<Props> = () => {
    return <UIPanel title={'Radar'}>
        <MapMasterComponent />
    </UIPanel>
}

export default UIRadar;
