import { useEffect, useState } from "react";
import { Schema } from "@colyseus/schema";
import {NonFunctionPropNames} from '@colyseus/schema/lib/types/HelperTypes';

export default function useSchemaValue<T extends Schema, U extends any | null>(schema: T | undefined | null, prop: NonFunctionPropNames<T>) : U | undefined {
    const [value, setValue] = useState<T[NonFunctionPropNames<T>]>();

    useEffect(() => {

        if (schema) {
            setValue(schema[prop]);

            schema.listen(prop, (v: T[NonFunctionPropNames<T>]) => {
                setValue(v);
            })
        }

    }, [schema, prop]);

    return value as unknown as U;
}
