import * as PIXI from 'pixi.js';

//@ts-ignore
import { ObjectPoolFactory } from '@pixi-essentials/object-pool';
import { GameObjectSchema } from '../../../../schemas/GameObjectSchema';
import MapObject from './object';
import { UpdateData } from '..';

export default class ObjectsLayer extends PIXI.Container {

    private shipPool = ObjectPoolFactory.build(MapObject);

    private objects = new Map<string, MapObject>();

    constructor(private adminMode: boolean) {
        super();

        this.shipPool.reserve(100);
    }

    update(data: UpdateData) {

        const existing = new Set(this.objects.keys());


        data.gameObjects.forEach(d => {
            const object = this.objects.get(d.id);

            if (object) {
                existing.delete(d.id);

                object.isTracked = Boolean(this.adminMode || data.tracked.find(t => t === d.id));
                object.isMe = Boolean(data.shipId === d.id);

                object.setSelected(d.id === data.selectedObject)

            } else {

                const newObject = this.add(d);

                newObject.isTracked = Boolean(this.adminMode || data.tracked.find(t => t === d.id));
                newObject.isMe = Boolean(data.shipId === d.id);


                newObject.x = d.x;
                newObject.y = d.y;
                newObject.rotation = d.angle;

                d.listen('x', (x => {
                    newObject.x = x;
                }))

                d.listen('y', (y => {
                    newObject.y = y;
                }))

                d.listen('angle', (angle => {
                    newObject.rotation = angle
                }));

                newObject.on('click', () => {
                    this.emit('object-selected', {
                        objectId: d.id
                    });
                })
            }

        })

        existing.forEach(id => {
            this.remove(id);
        })
    }

    add(data: GameObjectSchema): MapObject {
        const object = this.shipPool.allocate() as MapObject;
        object.type = data.type;

        this.addChild(object);
        this.objects.set(data.id, object);

        return object;
    }

    remove(id: string) {
        const ship = this.objects.get(id);

        if (ship) {
            this.shipPool.release(ship);
            this.removeChild(ship);

            this.objects.delete(id);
        }
    }
}


