import { useCallback, useMemo } from "react";
import { useGame, useMyShip } from "../../..";
import { TracktorBeamSchema } from "../../../../../schemas/TracktorBeamSchema";
import useBasicSchema from "../../use-basic-schema";
import useSetSchema from "../../use-set-schema";
import useDistance from "../use-distance";

export default function useTractorBeam() {
    const { ship } = useMyShip();
    const {containers, room} = useGame();
    const tracked = useSetSchema(ship?.tracking.trackedObjects);

    const isPulling = useBasicSchema<TracktorBeamSchema, boolean>(ship?.tractorBeam, 'isPulling');
    const pullingTarget = useBasicSchema<TracktorBeamSchema, string>(ship?.tractorBeam, 'pullingTarget');

    const targets = useMemo(() => {
        return containers.filter(c => tracked.includes(c.id));
    }, [containers, tracked])

    const startPulling = useCallback((targetId: string) => {
        if (room) {
            room.send('start-pull', {
                targetId
            });
        }
    }, [room]);

    const cancelPulling = useCallback(() => {
        if (room) {
            room.send('cancel-pull', {});
        }
    }, [room]);

    const targetDistance = useDistance(pullingTarget || '')

    return {
        targets,
        isPulling,
        pullingTarget,
        targetDistance,
        startPulling,
        cancelPulling
    }
}
