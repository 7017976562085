import { useEffect } from "react";
import { useForm } from "react-hook-form";
import useMic from "../../../../modules/server/hooks/game/panels/use-mic";
import PixiComponent from "./component";

type Props = {}

const MIN_RANGE = 50;
const MIN_ANGLE = 5;

const MicComponent: React.FC<Props> = () => {

    const { angle, range, setAngle, setRange } = useMic();

    const { register, setValue, watch } = useForm();

    const _range = watch('range')
    const _angle = watch('angle')

    useEffect(() => {
        const v = parseInt(_range);

        if (!isNaN(v) && v >= MIN_RANGE) {
            setRange(v);
        }
    }, [_range, setRange]);

    useEffect(() => {
        const v = parseInt(_angle);

        if (!isNaN(v) && v >= MIN_ANGLE) {
            setAngle(v);
        }
    }, [_angle, setAngle]);

    useEffect(() => {
        setValue('range', (range || 0) | 0);
    }, [setValue, range])

    useEffect(() => {
        setValue('angle', angle | 0);
    }, [setValue, angle])

    return <>
        <PixiComponent />
        <div>
            <div>
                Zasięg: <input type="range" min={MIN_RANGE} max="1000" step="1" defaultValue={range || 0} {...register('range')}/> {(range || 0) | 0}
            </div>
            <div>
                Zakres: <input type="range" min={MIN_ANGLE} max="180" step="1" defaultValue={angle} {...register('angle')} /> {angle | 0}
            </div>
        </div>
    </>
}

export default MicComponent;
