import { useMyShip } from "../../..";
import { MissionMessageSchema } from "../../../../../schemas/MissionMessageSchema";
import useArraySchema from "../../use-array-schema";

export default function useMissionComs() {
    const { ship } = useMyShip();
    const messages = useArraySchema<MissionMessageSchema>(ship?.missionComs.messages);

    return { messages };
}
