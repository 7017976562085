import { useMainModuleState } from "..";

export default function useIsLogged() {
    const { user, isLoading } = useMainModuleState();

    return {
        isLogged: Boolean(user),
        isLoading
    };
}
