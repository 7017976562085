import React from "react";
import styled from "styled-components";
import BotTitle from "../utils/box-title";
import SteerButtons from "./steer-buttons";

type Props = {}

const Container = styled.div`
    position: relative;
    height: 100%;

    display: flex;
    justify-content: stretch;
    align-items: stretch;

    .move-handle {
        position: absolute;

        top: 0;
        left: 0;
        right: 0;
        height: 1em;

        cursor: move;
    }

    .panel-box {
        width: 100%;
        border: 1px solid black;
        border-radius: 17px;
        overflow: hidden;

        color: #0c0a32;
        font-family: 'Jura', sans-serif;

        display: flex;
        flex-direction: column;
        background: white;
        background-image: linear-gradient(0deg, rgba(0,0,0,0.28) 0%, rgba(255,255,255,0.28) 100%);
    }

    .sterring {
        padding-top: 2em;
    }

    .steer-cube {
        width: 50%;
        margin: 2em 0 0 2em;
    }
`;

const GUI2Controlls: React.FC<Props> = () => {
    return <Container>
        <div className="move-handle"></div>
        <div className="panel-box">
            <div className="sterring">
                <BotTitle>Sterowanie</BotTitle>
                <div className="steer-cube">
                    <SteerButtons />
                </div>
            </div>
        </div>
    </Container>
}

export default GUI2Controlls;
