import { useCallback, useState } from "react";
import styled from "styled-components"
import useDecoys from "../../../modules/server/hooks/game/panels/use-decoys";
import CourseInput from "../../course-input";
import UIPanel from "../panel"

type Props = {}

const Container = styled.div`
    .course-wrapper {
        width: 150px;
        position: relative;
    }
`;

const UIDecoys: React.FC<Props> = () => {

    const { launchDecoy } = useDecoys();
    const [angle, setAngle] = useState(0);

    const onLaunch = useCallback(() => {
        launchDecoy(angle);
    }, [launchDecoy, angle])

    return <UIPanel title="Wabie">
        <Container>
            <div className="course-wrapper">
                <CourseInput onChange={setAngle} value={angle} />
            </div>
            <button onClick={onLaunch}>Wstrzej wabia!</button>
        </Container>
    </UIPanel>
}

export default UIDecoys;
