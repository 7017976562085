import styled from "styled-components";
import useConfig from "../../modules/main/hooks/use-config";
import useLogout from "../../modules/main/hooks/use-logout";

type Props = {

}

const Container = styled.div`
    width: 95%;
    display: flex;
    flex-direction: row-reverse;
    margin: 0%.5rem;

    .box {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 0.5em;
        background: white;
        border-radius: 1em;
    }

    .row {
        margin: .5em;
    }
`;

const UserInfo: React.FC<Props> = () => {
    const { logout } = useLogout();
    const { user } = useConfig()

    return <Container>
        <div className="box">
            <div className="row">
                Zalogowany jako: {user.nickname}
            </div>
            <div className="row">
                <button onClick={() => logout()}>Wyloguj</button>
            </div>
        </div>
    </Container>
}

export default UserInfo;
