import styled from "styled-components";

import gameHeaderUrl from '../../assets/images/game/header-full.jpg';

type Props = {}

const Container = styled.div`

    height: 23vw;
    max-height: 441px;
    background: url(${gameHeaderUrl}) 50% 50%;
    background-size: auto 100%;

`;

const PageHeader: React.FC<Props> = () => {
    return <Container>
        <div className="main-header"></div>
    </Container>
}

export default PageHeader;
