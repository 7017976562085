import React from "react";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import useGameLobbyRules from "../../modules/server/hooks/game-lobby/use-game-lobby-rules";
import { RuleNumberSchema } from "../../schemas/RuleNumberSchema";

type Props = {}

const Container = styled.div`
    width: 100%;
`;

const RulesForm: React.FC<Props> = () => {

    const { rules } = useGameLobbyRules();

    return <Container className="box">
        <h2>Zasady gry</h2>
        {rules && <table>
            <tbody>
                <tr>
                    <td>ID</td>
                    <td>Wartość</td>
                    <td>Opis</td>
                </tr>
                <RuleNumberRow rule={rules.shipMaxSpeedLevel0} />
                <RuleNumberRow rule={rules.shipMaxSpeedLevel1} />
                <RuleNumberRow rule={rules.shipMaxSpeedLevel2} />
                <RuleNumberRow rule={rules.shipMaxSpeedLevel3} />
            </tbody>
        </table>}
    </Container>
}


type RowNumberProps = {
    rule: RuleNumberSchema
}

const Row = styled.tr``;

const RuleNumberRow: React.FC<RowNumberProps> = ({ rule }) => {

    const { setRuleValue } = useGameLobbyRules();

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.value = '' + rule.value;
        }

        const remove = rule.listen('value', (v) => {
            console.log('value:', v);
            if (inputRef.current) {
                inputRef.current.value = '' + v;
            }
        })

        return () => {
            remove();
        }
    }, [rule]);

    const sendNewValue = useCallback(() => {
        if (inputRef.current) {
            const value = parseInt(inputRef.current.value);

            if (!isNaN(value)) {
                setRuleValue(rule.key, value);
            }
        }
    }, [setRuleValue, rule]);

    return <Row>
        <td>{rule.key}</td>
        <td><input type="number" ref={inputRef} onBlur={sendNewValue} /></td>
        <td>{rule.description}</td>
    </Row>
}

export default RulesForm;
