import * as PIXI from 'pixi.js';
import PixiApp from '..';

const COLOR = 0x3ec4fb

export default class TopoLayout extends PIXI.Container {

    private gfx = new PIXI.Graphics();
    private textPool: PIXI.Text[] = [];
    private nextTextIndex = 0;

    constructor(app: PixiApp) {
        super();

        this.addChild(this.gfx);

        this.textPool.forEach(text => {
            text.visible = false;
            text.style.fontSize = 12;
            text.anchor.set(.5);
            text.style.fill = COLOR;
            this.addChild(text);

        })
    }

    private getText(): PIXI.Text {
        if (this.textPool.length >= this.nextTextIndex + 1) {
            return this.textPool[this.nextTextIndex++];
        } else {
            const text = new PIXI.Text('');
            text.visible = false;
            text.style.fontSize = 12;
            text.anchor.set(.5);
            text.style.fill = COLOR;
            this.addChild(text);
            this.textPool.push(text);
            this.nextTextIndex++
            return text;
        }
    }


    public update(center: [number, number], width: number, height: number, zoom: number) {
        this.gfx.clear();

        this.gfx.lineStyle(1, COLOR, 1);

        const visibleWidth = width * zoom;
        const visibleHeight = height * zoom;

        const size = Math.max(visibleWidth, visibleHeight);

        const lineDist = getLineDist(size / 10);

        // console.log('topo#update', { zoom, visibleWidth, visibleHeight, lineDist });

        this.textPool.forEach(t => t.visible = false);

        this.nextTextIndex = 0;

        let textX = Math.floor(center[0] / lineDist) * lineDist / zoom;
        let textY = Math.floor(center[1] / lineDist) * lineDist / zoom;

        for (let i = Math.floor((center[0] - visibleWidth / 2) / lineDist); i < Math.ceil((center[0] + visibleWidth / 2) / lineDist); i++) {
            const cord = lineDist * i;
            const pos = cord / zoom;

            this.gfx.moveTo(pos, center[1]/zoom - visibleHeight);
            this.gfx.lineTo(pos, center[1]/zoom + visibleHeight);

            this.gfx.lineStyle(1, COLOR, 1);

            const text = this.getText();

            text.text = `${Math.round(cord)}`;
            text.visible = true;
            text.position.set(pos, textY);
            text.anchor.set(.5, -.1)

            this.nextTextIndex++;

        }

        for (let i = Math.floor((center[1] - visibleHeight / 2) / lineDist); i < Math.ceil((center[1] + visibleHeight / 2) / lineDist); i++) {

            const cord = lineDist * i;
            const pos = cord / zoom;

            this.gfx.moveTo(center[0]/zoom - visibleWidth, pos);
            this.gfx.lineTo(center[0]/zoom + visibleWidth, pos);

            this.gfx.lineStyle(1, COLOR, 1);

            const text = this.getText();

            text.text = `${Math.round(cord)}`;
            text.visible = true;
            text.position.set(textX, pos);
            text.anchor.set(1.1, .5)

            this.nextTextIndex++;
        }
    }
}


function getLineDist(raw: number) {
    const bases = [1, 2, 2.5, 5];

    for (let x = 0; x < 20; x++) {
        for (let b = 0; b < bases.length; b++) {
            const dec = Math.pow(10, x);
            const base = bases[b];

            if (raw < base * dec) {
                return base * dec;
            }
        }
    }

    return 100;
}
