import styled from "styled-components";
import useObjectInfo from "../../../modules/server/hooks/game/panels/use-object-info";
import UIPanel from "../panel"
import InfoBox from "./info-box";

type Props = {}

const Container = styled.div`
    min-height: 3em;
    display: flex;
    justify-content: stretch;
    align-items: center;
`;

const UIObjectInfo: React.FC<Props> = () => {

    const { object } = useObjectInfo();

    return <UIPanel title="Dane obiektu">
        <Container>
            {object && <div>
                {
                    object.known && <div>
                        <InfoBox id={object.id} />
                    </div>
                }
                {
                    !object.known && <div>
                        Obiekt niezidentyfikowany
                    </div>
                }
            </div>}
            {!object && <div style={{textAlign: 'center', width: '100%'}}>
                Brak zaznaczonego obiektu
            </div>}
        </Container>
    </UIPanel>
}

export default UIObjectInfo;
