import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import useConfig from './modules/main/hooks/use-config';
import useIsLogged from './modules/main/hooks/use-is-logged';
import { ServerProvider } from './modules/server';
import GamePage from './routes/game';
import GameAdminPage from './routes/game-admin';
import GameLobbyPage from './routes/game-lobby';

import HomePage from './routes/home';
import LoginPage from './routes/login';
import { getGameAdminRoute, getGameLobbyRoute, getGameRoute, getLoginRoute, getServerLobbyRoute } from './routes/routes';
import ServerLobby from './routes/server-lobby';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`;

export default function App() {
    const { gameAuthToken } = useConfig();

    return (
        <BrowserRouter>
            <Container>
                <Switch>

                    <OnlyForUnloggedRoute path={getLoginRoute()}>
                        <LoginPage />
                    </OnlyForUnloggedRoute>

                    <OnlyForLoggedRoute>
                        <ServerProvider token={gameAuthToken}>
                            <Switch>
                                <Route path={getGameLobbyRoute()}>
                                    <GameLobbyPage />
                                </Route>
                                <Route path={getGameRoute()}>
                                    <GamePage />
                                </Route>

                                <Route path={getGameAdminRoute()}>
                                    <GameAdminPage />
                                </Route>

                                <Route component={ServerLobby} />
                            </Switch>
                        </ServerProvider>
                    </OnlyForLoggedRoute>

                    <Route component={HomePage} />
                </Switch>
            </Container>
        </BrowserRouter>
    )
}

const OnlyForLoggedRoute: React.FC<any> = ({ children, ...rest }: any) => {

    const { isLogged } = useIsLogged();

    return <Route {...rest}>{
        isLogged
            ? children
            : <Redirect to={getLoginRoute()} />
    } </Route>
}

const OnlyForUnloggedRoute = ({ children, ...rest }: any) => {

    const { isLogged } = useIsLogged();

    return <Route {...rest}>{
        isLogged
            ? <Redirect to={getServerLobbyRoute()} />
            : children
    } </Route>
}
