import React, { useCallback, useMemo, useRef } from "react";
import styled from "styled-components";

type Props = {
    value: number,
    onChange(value: number): void;
}

const Container = styled.div`
    width: 100%;
    padding-top: 100%;

    .inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background: #f0f0f0;

        :after {
            content: '';
            width: 100px;
            height: 100px;
            border: 1px solid black;
            position: absolute;

            border-radius: 50%;
            top: 25px;
            left: 25px;
        }
    }

    .handle {
        position: absolute;
        width: 15px;
        height: 15px;

        background: orange;
        border-radius: 50%;
    }

    .value {
        pointer-events: none;
        user-select: none;
    }
`;

const CourseInput: React.FC<Props> = ({ onChange, value }) => {

    const radius = 50;
    const center = 75;

    const inner = useRef<HTMLDivElement>(null)

    const onClick = useCallback((e: React.MouseEvent) => {
        if (inner.current) {
            const s = inner.current.getBoundingClientRect();

            const dx = e.clientX - s.x - center;
            const dy = s.y - e.clientY + center;

            onChange((Math.atan2(dx, dy) * (180 / Math.PI) + 360) % 360);
        }
    }, [onChange])

    const radians = useMemo(() => {
        return value * (Math.PI / 180);
    }, [value])

    const x = useMemo(() => {
        const posX = Math.sin(radians) * radius;
        return posX;
    }, [radius, radians])

    const y = useMemo(() => {
        const posY = Math.cos(radians) * radius;
        return posY;
    }, [radius, radians])

    return <Container>
        <div className="inner"
            onClick={onClick}
            ref={inner}>
            <div className="handle" style={{
                left: center + x - (15 / 2),
                top: center - y - (15 / 2)
            }}
            >

            </div>
            <div className="value">
                {value | 0}
            </div>
        </div>

    </Container>
}

export default CourseInput;
