import { useParams } from "react-router";
import { GameProvider, LocalStateProvider, MyShipProvider } from "../../modules/server";
import Game from "./game";

export default function GamePage() {

    const { id } = useParams<{ id: string }>();

    return <GameProvider id={id}>
        <MyShipProvider>
            <LocalStateProvider>
                <Game />
            </LocalStateProvider>
        </MyShipProvider>
    </GameProvider>;
}
