import { useMemo, useState } from "react";
import styled from "styled-components";
import useMissionComs from "../../../modules/server/hooks/game/panels/use-mission-coms";
import UIPanel from "../panel"

type Props = {}

const Container = styled.div`
    .messages {
        border-bottom: 1px solid black;
    }

    tr {
        cursor: pointer;
        &:hover {
            background: yellow;
        }

        &.selected {

            background: lightgreen;
            &:hover {
                background: lightgreen;
            }
        }
    }
`;

const UIMissionComs: React.FC<Props> = () => {

    const { messages } = useMissionComs();
    const [selectedId, select] = useState<string>();

    const message = useMemo(() => {
        return messages.find(m => m.id === selectedId);
    }, [messages, selectedId]);

    return <UIPanel title="Misja">
        <Container>
            <div className="messages">
                Wiadomości:

                <table>
                    <tbody>
                        {
                            messages.map(msg => {
                                return <tr key={msg.id} onClick={() => { select(msg.id) }} className={`${selectedId === msg.id ? 'selected' : ''}`}>
                                    <td>[{msg.from}] {msg.title}</td>
                                </tr>
                            })
                        }

                    </tbody>
                </table>
            </div>
            <div className="message">
                {message && <>
                    <h4>{message.title}</h4>
                    <p>{message.content}</p>
                </>}
            </div>
        </Container>
    </UIPanel>
}

export default UIMissionComs;
