import { useRef, useEffect, useCallback } from "react";
import useRadar from "../../modules/server/hooks/game/panels/use-radar";
import PixiComponent, { API, MapOptions } from "./component";

type Props = {
    options?: Partial<MapOptions>,
    centerOn?: string
}

const MapMasterComponent: React.FC<Props> = ({centerOn, options}) => {

    const map = useRef<API>(null);
    const { gameObjects, shipId, tracked, selectObject, areas, selectedObject } = useRadar();

    const onObjectSelected = useCallback((x) => {
        selectObject(x)
    }, [selectObject])

    useEffect(() => {
        if ((centerOn || shipId) && map.current) {
            map.current.setCenter(centerOn || shipId || null);
        }
    }, [centerOn, shipId])

    const initCenter = useRef<string>(shipId || '');

    useEffect(() => {
        if (map.current) {
            map.current.update({ gameObjects, shipId, tracked, areas, selectedObject });
        }
    }, [gameObjects, shipId, tracked, areas, selectedObject]);

    return <PixiComponent ref={map} onObjectSelected={onObjectSelected} centerOn={initCenter.current} options={options || {}} />
}

export default MapMasterComponent;
