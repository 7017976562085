import { useCallback, useMemo } from "react";
import { useGame, useMyShip } from "../../..";
import { RepairInProgressSchema } from "../../../../../schemas/RepairInProgressSchema";
import { ShipStatusSchema } from "../../../../../schemas/ShipStatusSchema";
import useBasicSchema from "../../use-basic-schema";
import useMapSchema from "../../use-map-schema";

export default function useShipStatus() {
    const { ship } = useMyShip();
    const { room } = useGame();

    const hull = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'hull');

    const mainEngine = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'mainEngine');
    const maneveurEngine = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'maneveurEngine');
    const hiperEngine = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'hiperEngine');
    const radar = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'radar');
    const energyRadar = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'energyRadar');
    const microphone = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'microphone');
    const storage = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'storage');
    const torpedos = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'torpedos');
    const shields = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'shields');
    const tracking = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'tracking');
    const scaner = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'scaner');

    const repairCrews = useBasicSchema<ShipStatusSchema, number>(ship?.status, 'repairCrews');

    const repairsInProgress = useMapSchema<RepairInProgressSchema>(ship?.status.repairsInProgress);

    const sendCrew = useCallback((system: string) => {
        if (room) {
            room.send('send-crew', {
                system
            })
        }
    }, [room])

    const availableCrew = useMemo(() => {
        return (repairCrews || 0) - repairsInProgress.length;
    }, [repairsInProgress, repairCrews])

    return {
        hull,
        availableCrew,
        repairsInProgress,

        mainEngine,
        maneveurEngine,
        hiperEngine,
        radar,
        energyRadar,
        microphone,
        storage,
        torpedos,
        shields,
        tracking,
        scaner,

        sendCrew
    }
}
