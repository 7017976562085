import styled from "styled-components";
import useCargo from "../../../modules/server/hooks/game/panels/use-cargo";
import UIPanel from "../panel"

type Props = {}

const Container = styled.div`
`;

const UICargo: React.FC<Props> = () => {

    const {cargo, isDocked, loadOut} = useCargo();

    return <UIPanel title="Ładownia">
        <Container>
            {JSON.stringify(cargo)}
            <table>
                <tbody>
                    {
                        cargo.map(c => {
                            return <tr key={c.type}>
                                <td>{c.type}</td>
                                <td>{c.count}</td>
                                <td><button disabled={!isDocked} onClick={() => {loadOut(c.type)}}>Wyładuj</button></td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </Container>
    </UIPanel>
}

export default UICargo;
