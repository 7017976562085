import { useMyShip } from "../../../modules/server";
import CrewController from "../../crew-controller";
import UIPanel from "../panel"

type Props = {}

const UICrew: React.FC<Props> = () => {

    const {ship} = useMyShip();

    return <UIPanel title={'Załoga'} style={{height: '100%'}} panelStyle={{height: '100%'}}>
        {ship && <CrewController ship={ship} />}
    </UIPanel>
}

export default UICrew;
