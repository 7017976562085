import { useGame, useLocalState } from "../../modules/server";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import styled from "styled-components";
import { lazy, Suspense, useEffect } from "react";

import gameHeaderUrl from '../../assets/images/game/header.png';
const AdminPanel = lazy(() => import("./admin-panel"));

type Props = {}

const Container = styled.div`
    width: 100%;

    @keyframes shield-damaged {
        from {
            background-color: blue;
        }
        to {
            background-color: #676767;
        }
    }

    @keyframes shield-downed {
        from {
            background-color: yellow;
        }
        to {
            background-color: #676767;
        }
    }

    @keyframes hull-damaged {
        from {
            background-color: red;
        }
        to {
            background-color: #676767;
        }
    }

    .header {
        height: 25vh;
        max-height: 370px;
        background: url(${gameHeaderUrl}) 50% 50% no-repeat;
        background-size: contain;
    }

    .panels-container {
        border: 1px solid #3f3f3f;
        background: #676767;

        &.shield-downed {
            animation: shield-downed 1s;
        }

        &.shield-damaged {
            animation: shield-damaged 1s;
        }

        &.hull-damaged {
            animation: hull-damaged 1s;
        }

    }

    .console-wrapper {
        width: 98%;
        margin: 0 auto;
    }
`;

const GameAdmin: React.FC<Props> = () => {

    const { isConnected } = useGame();

    const { setAdminMode } = useLocalState();

    useEffect(() => {
        setAdminMode(true);

        return () => {
            setAdminMode(false);
        }
    }, [setAdminMode])

    return <Container>
        {
            isConnected && <Suspense fallback={<p>Ładowanie....</p>}>
                <AdminPanel />
            </Suspense>
        }
        {
            !isConnected && <p>Łączę....</p>
        }
    </Container>;
}

export default GameAdmin;
