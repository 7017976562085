import * as PIXI from "pixi.js";
import { UpdateData } from "..";

export default class AreaLayer extends PIXI.Container {
    private gfx = new PIXI.Graphics();

    private asteroids = PIXI.Texture.from('asteroids');

    constructor() {
        super();

        this.addChild(this.gfx);
    }

    update(data: UpdateData) {
        this.gfx.clear();
        // this.gfx.tint = 0xa4fff5;

        data.areas.forEach(area => {
            if (area.thickness > 0) {
                switch (area.shape.type) {
                    case 'circle':
                        this.gfx.lineStyle(0);
                        // if (area.energy) {
                        //     this.gfx.beginFill(0x00eeee, 1);
                        // } else {
                            this.gfx.beginTextureFill({
                                texture: this.asteroids
                            })
                        // }
                        this.gfx.drawCircle(area.x, area.y, area.shape.radius);
                        this.gfx.endFill();
                        break;
                }
            }
        })
    }
}
