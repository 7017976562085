export const BASE_LAYOUTS = {
  "lg": [
    {
      "i": "admin-map",
      "x": 0,
      "y": 0,
      "w": 12,
      "h": 8
    },
    {
      "i": "rot-engine",
      "x": 9,
      "y": 0,
      "w": 3,
      "h": 2
    },
    {
      "i": "map-tester",
      "x": 0,
      "y": 0,
      "w": 6,
      "h": 4
    },
    {
      "w": 6,
      "h": 3,
      "x": 0,
      "y": 0,
      "i": "radar",
      "moved": false,
      "static": false
    },
    {
      "w": 3,
      "h": 2,
      "x": 6,
      "y": 0,
      "i": "main-engine",
      "moved": false,
      "static": false
    },
    {
      "w": 3,
      "h": 1,
      "x": 6,
      "y": 2,
      "i": "engine-mode",
      "moved": false,
      "static": false
    },
    {
      "w": 2,
      "h": 1,
      "x": 0,
      "y": 8,
      "i": "object-info",
      "moved": false,
      "static": false
    },
    {
      "w": 2,
      "h": 1,
      "x": 2,
      "y": 8,
      "i": "scanner",
      "moved": false,
      "static": false
    },
    {
      "w": 2,
      "h": 1,
      "x": 4,
      "y": 8,
      "i": "tracking-list",
      "moved": false,
      "static": false
    },
    {
      "w": 3,
      "h": 2,
      "x": 0,
      "y": 6,
      "i": "reactor",
      "moved": false,
      "static": false
    },
    {
      "w": 3,
      "h": 2,
      "x": 3,
      "y": 6,
      "i": "status",
      "moved": false,
      "static": false
    },
    {
      "w": 3,
      "h": 4,
      "x": 9,
      "y": 0,
      "i": "torpedos",
      "moved": false,
      "static": false
    },
    {
      "w": 3,
      "h": 3,
      "x": 6,
      "y": 3,
      "i": "torpedo-controll",
      "moved": false,
      "static": false
    },
    {
      "w": 3,
      "h": 2,
      "x": 9,
      "y": 4,
      "i": "decoys",
      "moved": false,
      "static": false
    },
    {
      "w": 3,
      "h": 2,
      "x": 0,
      "y": 9,
      "i": "shields",
      "moved": false,
      "static": false
    },
    {
      "w": 2,
      "h": 2,
      "x": 3,
      "y": 9,
      "i": "tractor-beam",
      "moved": false,
      "static": false
    },
    {
      "w": 2,
      "h": 2,
      "x": 5,
      "y": 9,
      "i": "cargo",
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 2,
      "x": 7,
      "y": 6,
      "i": "mission-coms",
      "moved": false,
      "static": false
    },
    {
      "w": 6,
      "h": 3,
      "x": 0,
      "y": 3,
      "i": "energy-radar",
      "moved": false,
      "static": false
    },
    {
      "w": 2,
      "h": 3,
      "x": 10,
      "y": 8,
      "i": "mic",
      "moved": false,
      "static": false
    }
  ],
  "md": [
    {
      "i": "admin-map",
      "x": 0,
      "y": 0,
      "w": 10,
      "h": 6
    },
    {
      "i": "rot-engine",
      "x": 6,
      "y": 3,
      "w": 4,
      "h": 2
    },
    {
      "w": 6,
      "h": 3,
      "x": 0,
      "y": 0,
      "i": "radar",
      "moved": false,
      "static": false
    },
    {
      "w": 6,
      "h": 3,
      "x": 0,
      "y": 3,
      "i": "energy-radar",
      "moved": false,
      "static": false
    },
    {
      "w": 4,
      "h": 2,
      "x": 6,
      "y": 1,
      "i": "main-engine",
      "moved": false,
      "static": false
    },
    {
      "w": 4,
      "h": 1,
      "x": 6,
      "y": 0,
      "i": "engine-mode",
      "moved": false,
      "static": false
    },
    {
      "w": 3,
      "h": 1,
      "x": 3,
      "y": 8,
      "i": "object-info",
      "moved": false,
      "static": false
    },
    {
      "w": 3,
      "h": 1,
      "x": 0,
      "y": 8,
      "i": "scanner",
      "moved": false,
      "static": false
    },
    {
      "w": 3,
      "h": 1,
      "x": 7,
      "y": 12,
      "i": "tracking-list",
      "moved": false,
      "static": false
    },
    {
      "w": 3,
      "h": 2,
      "x": 0,
      "y": 6,
      "i": "reactor",
      "moved": false,
      "static": false
    },
    {
      "w": 3,
      "h": 2,
      "x": 3,
      "y": 6,
      "i": "status",
      "moved": false,
      "static": false
    },
    {
      "w": 4,
      "h": 3,
      "x": 6,
      "y": 3,
      "i": "torpedos",
      "moved": false,
      "static": false
    },
    {
      "w": 4,
      "h": 2,
      "x": 6,
      "y": 6,
      "i": "torpedo-controll",
      "moved": false,
      "static": false
    },
    {
      "w": 3,
      "h": 2,
      "x": 7,
      "y": 8,
      "i": "decoys",
      "moved": false,
      "static": false
    },
    {
      "w": 4,
      "h": 2,
      "x": 0,
      "y": 9,
      "i": "shields",
      "moved": false,
      "static": false
    },
    {
      "w": 2,
      "h": 2,
      "x": 5,
      "y": 11,
      "i": "tractor-beam",
      "moved": false,
      "static": false
    },
    {
      "w": 2,
      "h": 2,
      "x": 4,
      "y": 9,
      "i": "cargo",
      "moved": false,
      "static": false
    },
    {
      "w": 5,
      "h": 2,
      "x": 0,
      "y": 11,
      "i": "mission-coms",
      "moved": false,
      "static": false
    },
    {
      "w": 2,
      "h": 2,
      "x": 8,
      "y": 10,
      "i": "mic",
      "moved": false,
      "static": false
    }
  ],
  "sm": [
    {
      "i": "admin-map",
      "x": 0,
      "y": 0,
      "w": 6,
      "h": 4
    },
    {
      "i": "radar",
      "x": 0,
      "y": 0,
      "w": 3,
      "h": 3
    },
    {
      "i": "main-engine",
      "x": 3,
      "y": 0,
      "w": 3,
      "h": 2
    },
    {
      "i": "rot-engine",
      "x": 3,
      "y": 0,
      "w": 3,
      "h": 2
    },
    {
      "i": "engine-mode",
      "x": 3,
      "y": 0,
      "w": 3,
      "h": 1
    },
    {
      "i": "object-info",
      "x": 0,
      "y": 6,
      "w": 3,
      "h": 1
    },
    {
      "i": "scanner",
      "x": 0,
      "y": 7,
      "w": 2,
      "h": 1
    },
    {
      "i": "tracking-list",
      "x": 0,
      "y": 8,
      "w": 3,
      "h": 1
    }
  ],
  "xxs": [
    {
      "i": "admin-map",
      "x": 0,
      "y": 0,
      "w": 2,
      "h": 1
    },
    {
      "i": "radar",
      "x": 0,
      "y": 3,
      "w": 2,
      "h": 1
    },
    {
      "i": "main-engine",
      "x": 0,
      "y": 0,
      "w": 2,
      "h": 2
    },
    {
      "i": "rot-engine",
      "x": 0,
      "y": 2,
      "w": 2,
      "h": 2
    },
    {
      "i": "engine-mode",
      "x": 0,
      "y": 0,
      "w": 2,
      "h": 1
    }
  ],
  "xs": [
    {
      "i": "admin-map",
      "x": 0,
      "y": 0,
      "w": 4,
      "h": 2
    },
    {
      "i": "radar",
      "x": 0,
      "y": 0,
      "w": 4,
      "h": 2
    },
    {
      "i": "main-engine",
      "x": 0,
      "y": 1,
      "w": 4,
      "h": 2
    },
    {
      "i": "rot-engine",
      "x": 0,
      "y": 2,
      "w": 4,
      "h": 2
    },
    {
      "i": "engine-mode",
      "x": 0,
      "y": 3,
      "w": 4,
      "h": 1
    }
  ]
}

export default function getLayouts() {
  try {
    const stored = JSON.parse(localStorage.getItem('game-layouts') || '') || BASE_LAYOUTS;
    const base = BASE_LAYOUTS;

    return {
      lg: marge(base.lg, stored.lg),
      md: marge(base.md, stored.md),
      sm: marge(base.sm, stored.sm),
      xxs: marge(base.xxs, stored.xxs),
      xs: marge(base.xs, stored.xs),
    }
  } catch (e) {
    return BASE_LAYOUTS;
  }

}



function marge(base: { i: string }[], stored: { i: string }[]): any[] {
  const ids = new Set([
    ...base.map(x => x.i),
    ...stored.map(x => x.i)
  ]);

  const result: any[] = [];

  ids.forEach(id => {
    const s = stored.find(s => s.i === id);

    if (s) {
      result.push(s);
      return;
    }

    const b = stored.find(b => b.i === id);

    if (b) {
      result.push(b);
      return;
    }
  });

  return result;
}
