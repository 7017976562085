import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import useMainEngine from "../../../modules/server/hooks/game/panels/use-main-engine";
import CourseInput from "../../course-input";
import UIPanel from "../panel";

type Props = {}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    .info {
        border-bottom: 1px solid black;

        display: flex;
        flex-direction: row;

        justify-content: space-between;
    }

    .inactive {
        padding: .5em;
        background: #fdabab;
        text-align: center;
    }

    form {
    }

    .buttons {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

const UIMainEngine: React.FC<Props> = () => {

    const { isActive, maxVelosity, setParams, course, velosity, powerLevel } = useMainEngine();
    const { register, handleSubmit, setValue } = useForm();

    const [_course, _setCourse] = useState(course || 0);

    const onSubmit = useCallback((data) => {
        const course = _course;
        const velosity = parseFloat(data.velosity);

        if (!isNaN(course) && !isNaN(velosity)) {
            setParams({
                course, velosity
            });
        }

    }, [setParams, _course]);

    useEffect(() => {
        setValue('velosity', velosity);
        _setCourse(course);
    }, [setValue, velosity, course])

    const onLeft = useCallback(() => {

        const v = course - 5

        setParams({
            velosity: velosity || 0,
            course: v < 0 ? v + 360 : v
        })
    }, [setParams, course, velosity]);

    const onRight = useCallback(() => {

        const v = course + 5

        setParams({
            velosity: velosity || 0,
            course: v >= 360 ? v - 360 : v
        })
    }, [setParams, course, velosity]);

    const onFaster = useCallback(() => {

        setParams({
            velosity: Math.min(200, (velosity || 0) + 5),
            course,
        })
    }, [setParams, course, velosity]);

    const onSlower = useCallback(() => {

        setParams({
            velosity: Math.max(0, (velosity || 0) - 5),
            course,
        })
    }, [setParams, course, velosity]);

    return <UIPanel title={'Silnik główny'}>
        <Container>
            <div className="info">
                <div>
                    Poziom zasilania: {powerLevel}
                </div>
                <div>
                    Max. prędkość: {maxVelosity}
                </div>
            </div>
            {
                !isActive && <div className="inactive">
                    Silnik nie aktywny!
                </div>
            }
            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ display: 'flex' }}>
                    <label style={{ flex: '0 0 50%' }}>
                        Ustaw prędkość:<br />
                        <input type="range" min={0} max={maxVelosity} {...register('velosity')} />{velosity}
                    </label>
                    <div style={{ width: '150px', position: 'relative' }}>
                        <CourseInput value={_course} onChange={_setCourse} />
                    </div>
                </div>
                <button type="submit">Zastosuj</button>
            </form>
            <h4>Bezpośrednie sterowanie</h4>
            <div className="buttons">
                <button style={{ gridColumn: 1, gridRow: 2 }} onClick={onLeft}>Lewo</button>
                <button style={{ gridColumn: 3, gridRow: 2 }} onClick={onRight}>Prawo</button>
                <button style={{ gridColumn: 2, gridRow: 1 }} onClick={onFaster}>Szybciej</button>
                <button style={{ gridColumn: 2, gridRow: 2 }} onClick={onSlower}>Wolniej</button>

            </div>
        </Container>
    </UIPanel>
}

export default UIMainEngine;
