import { useCallback, useMemo } from "react";
import { useMyShip, useGame } from "../../..";
import { MicSchema } from "../../../../../schemas/MicSchema";
import useBasicSchema from "../../use-basic-schema";

export default function useMic() {
    const { ship } = useMyShip();
    const { room, gameObjects, shipId } = useGame();

    const direction = useBasicSchema<MicSchema, number>(ship?.mic, 'direction');
    const angle = useBasicSchema<MicSchema, number>(ship?.mic, 'angle');
    const range = useBasicSchema<MicSchema, number>(ship?.mic, 'range');

    const setAngle = useCallback((value: number) => {
        if (room) {
            room.send('set-mic-angle', {
                value: value * (Math.PI / 180)
            })
        }
    }, [room])

    const setRange = useCallback((value: number) => {
        if (room) {
            room.send('set-mic-range', {
                value
            })
        }
    }, [room])

    const setDirection = useCallback((value: number) => {
        if (room) {
            room.send('set-mic-direction', {
                value
            })
        }
    }, [room])

    return {
        direction,
        angle: useMemo(() => {
            return (angle || 0) * (180 / Math.PI)
        }, [angle]),
        range,
        gameObjects,
        shipId,

        setAngle,
        setRange,
        setDirection
    }
}
